import React from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { updateDemographicDetails } from '../../actions/demographicActions';
import CheckboxQuestion from '../CheckboxQuestion/CheckboxQuestion';
import TextQuestion from '../TextQuestion/TextQuestion';
import DropdownQuestion from '../DropdownQuestion/DropdownQuestion';
import NumericQuestion from '../NumericQuestion/NumericQuestion';
import OptionalForm from './OptionalForm';
import GriddedQuestion from './GriddedQuestion';

export class BusinessForm extends React.Component {
  updateState(key, value) {
    this.props.dispatch(updateDemographicDetails(key, value));
  }

  render() {
    return (
      <OptionalForm prevButton={true} nextButton={true}>
        <GriddedQuestion number="4">
          <TextQuestion text="Name of Business" value={this.props.business_name}
                        onChange={(value) => this.updateState("business_name", value)}
                        autoFocus={true}/>
        </GriddedQuestion>
        <GriddedQuestion number="5">
          <NumericQuestion small text="Percent of business you own" value={this.props.percent_business_owned}
                        label="%" onChange={(value) => this.updateState("percent_business_owned", value)}/>
        </GriddedQuestion>
        <GriddedQuestion number="6">
          <DropdownQuestion text="Total number of employees" value={this.props.number_employees}
                        onChange={(value) => this.updateState("number_employees", value)}
                        options={["Self-Employed", "1-10 employees", "11-50 employees", "51-200 employees", "201-500 employees", "500+ employees"]}/>
        </GriddedQuestion>
        <GriddedQuestion number="7" value={this.props.business_types}>
          <CheckboxQuestion label="Types of business (industry)"
                            answers={["Retail", "Construction", "Information Technology", "Utilities", "Mining",
                                      "Manufacturing", "Finance and Insurance", "Wholesale Trade", "Public Administration",
                                      "Educational Services", "Real Estate and Rental and Leasing",
                                      "Healthcare and Social Assistance", "Accommodations and Food Services",
                                      "Arts, Entertainment and Recreation", "Transportation and Warehousing",
                                      "Professional, Scientific and Technical Services", "Management of Companies and Enterprises",
                                      "Agriculture, Forestry, Fishing and Hunting", "Administrative and Support",
                                      "Waste Management and Remediation Services"]}
                            customAnswers={["Other"]}
                            value={this.props.business_types}
                            onChange={(value) => this.updateState("business_types", value)}/>
        </GriddedQuestion>
      </OptionalForm>
     )
  }
}

const mapStateToProps = store => {
  return {
    business_name: store.demographic.demographicDetails.business_name,
    percent_business_owned: store.demographic.demographicDetails.percent_business_owned,
    number_employees: store.demographic.demographicDetails.number_employees,
    business_types: store.demographic.demographicDetails.business_types
  }
}

export default withRouter(connect(mapStateToProps)(BusinessForm))
