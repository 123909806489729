import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button, Container, Form, Grid, Input } from "semantic-ui-react";
import { confirmPhoneNumber, displayMfaRegistration, verifyMfa } from "../../actions/loginActions";
import ModalError from "../ModalError/ModalError";
import { helpdeskLink } from "../../utils/ExternalLinks";


export class Response2FaScreen extends Component {
  state = {
    code: '',
  };

  handleRef = (component) => (this.ref = component);

  handleCodeChange = (e) => {
    this.setState({ code: e.target.value });
  }

  handleSubmit = (e) => {
    var message = { code: this.state.code }

    if (this.props.isUserLoggedIn) {
      this.props.dispatch(confirmPhoneNumber(message));
    } else {
      this.props.dispatch(verifyMfa(message));
    }
    
  }

  handlePhoneReentry = (e) => {
    this.props.dispatch(displayMfaRegistration());
  }

  
  displaySupportMessage = () => {
    const helpDeskLink = (
      <a
        className="help-desk-link"
        href={helpdeskLink()}
        target="_blank"
        rel="noopener noreferrer"
      >
        Support Center
      </a>
    );


    if (!this.props.mfaEnabled) {
      return(
        <div>
          Didn't receive a code? contact the {this.helpDeskLink}
        </div>
      )
    } else {
      return(
        <div>
          Didn't receive a code? <a onClick={this.handlePhoneReentry.bind(this)}>Check your phone number</a> or contact {this.helpDeskLink}
        </div>
      )
    }
  }

  render() {
    return (
      <Container className="login-screen">
        <ModalError error={this.props.userError} />
        <Grid className="middle aligned">
          <Grid.Row>
            <Grid.Column width={16}>
              We texted your mobile phone number. Please enter the code
              below to sign in.
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Form onSubmit={this.handleSubmit.bind(this)}>
          <Form.Field required>
            <label>Code:</label>

            <Input type="text"
                   name="code"
                   placeholder="Code"
                   value={this.state.code}
                   aria-label="MFA sign in code"
                   onChange={this.handleCodeChange.bind(this)} />
            { this.displaySupportMessage() }
          </Form.Field>
          <Grid centered>
            <Grid.Row>
              <Grid.Column textAlign="center">
                <Button
                  className="primary"
                  aria-label="Sign In"
                  type="submit"
                >
                  Verify
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      </Container>
    )
  }
}

const mapStateToProps = store => {
  return {
    userError: store.login.userError,
    mfaEnabled: store.login.mfaEnabled,
    isUserLoggedIn: store.login.isUserLoggedIn,
  }
}

export default withRouter(connect(mapStateToProps)(Response2FaScreen))
