import React from "react";
import { Grid } from "semantic-ui-react";
import DynamicImage from "../HelperComponents/DynamicImage";

const PathCompleted = () => (
  <Grid.Row>
    <Grid.Column className="path-progress-finished">
      <DynamicImage
        src="static_content/progress_image.png"
        size="mini"
        alt="Ascent Mountain Progress Image"
        floated={"left"}
      />
      <b>Congrats!</b> You've finished your Journey!
    </Grid.Column>
  </Grid.Row>
);

export default PathCompleted;
