import React, { Component } from "react";
import { Button, Container, Divider } from "semantic-ui-react";

import "../SignUpForm/SignUpForm.scss";

export class DeleteSuccess extends Component {
  handleRef = (component) => (this.ref = component);

  handleSubmit = (e) => {
    e.preventDefault();
  };

  render() {
    return (
      <Container
        className="profile-modal-container"
        onKeyDown={this.handleKeyPress}
      >
        <div className="profile-modal-messaging">
          <p>
            <span className="profile-modal-emphasis">
              Your Ascent account has been successfully deleted.
            </span>
          </p>

          <p>
            We wish you all the best in your business endeavors. <br />
            Please be sure to come back at any time!
          </p>
        </div>

        <Divider />
        <div className="profile-modal-button-container">
          <Button
            aria-label="Close"
            className="profile-modal-button"
            size="large"
            onClick={this.props.close}
            primary
            content="Close"
          />
        </div>
      </Container>
    );
  }
}

export default DeleteSuccess;
