import React, { Component } from "react";
import InfographicBlockManager from "../InfographicBlockManager";

export default class Columns extends Component {
  renderColumns(columns) {
    return columns.map(column => {
      return (
        <div className="column column-block">
          <InfographicBlockManager block={column} />
        </div>
      );
    });
  }

  render() {
    const { content } = this.props;

    if (content) {
      return <div className="row">{this.renderColumns(content)}</div>;
    }
  }
}
