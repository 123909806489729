import React, { Component } from "react";
import { Breadcrumb, Grid, Icon } from "semantic-ui-react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  linkToJourney,
  linkToExcursion,
  linkToPath,
  linkToDashboard,
} from "../../utils/InternalLinks";
import { isHomepageEnabled } from "../../utils/EnvironmentFunctions";
import "./GlobalBreadcrumb.scss";

export class GlobalBreadcrumb extends Component {
  convertLinkToBreadcrumb(link, isLast) {
    if (!link || !link.name) {
      return null;
    }
    const divider = isLast ? null : (
      <Breadcrumb.Divider icon="triangle right" />
    );

    return (
      <React.Fragment key={link.name}>
        <Breadcrumb.Section
          id={link.id}
          className="global-breadcrumb-section"
          as={isLast ? null : Link}
          to={link.url}
          link={!isLast}
          active={isLast}
        >
          {link.name}
        </Breadcrumb.Section>
        {divider}
      </React.Fragment>
    );
  }

  getParentLink() {
    if (this.props.match.params.eventId) {
      return (
        <Link
          to={linkToExcursion(
            this.props.match.params.id,
            this.props.match.params.topicId
          )}
        >
          {this.props.topicName}
        </Link>
      );
    }
    if (this.props.match.params.topicId) {
      return (
        <Link to={linkToJourney(this.props.match.params.id)}>
          {this.props.pathName}
        </Link>
      );
    }
    if (this.props.match.params.id) {
      return <Link to={linkToDashboard()}>{isHomepageEnabled() ? "Dashboard" : process.env.REACT_APP_SITE_NAME}</Link>;
    }

    return <Link to={"/"}>Home</Link>;
  }

  renderMobile() {
    return (
      <Grid>
        <Grid.Column mobile={2}><Icon name="caret left" /></Grid.Column>
        <Grid.Column id="breadcrumb-links" mobile={14}>{this.getParentLink()}</Grid.Column>
      </Grid>
    );
  }

  render() {
    if (this.props.isMobile) {
      return this.renderMobile();
    }

    var links = [];
    if (isHomepageEnabled()) {
      links.push({ url: "/", name: "Home", id: "breadcrumb-home" });
    }
    links.push({
      url: linkToDashboard(),
      name: isHomepageEnabled() ? "Dashboard" : process.env.REACT_APP_SITE_NAME,
      id: "breadcrumb-dashboard",
    });
    if (this.props.pathName) {
      links.push({
        url: linkToJourney(this.props.match.params.id),
        name: this.props.pathName,
        id: "breadcrumb-journey",
      });
    }
    if (this.props.topicName) {
      links.push({
        url: linkToExcursion(
          this.props.match.params.id,
          this.props.match.params.topicId
        ),
        name: this.props.topicName,
        id: "breadcrumb-excursion",
      });
    }
    if (this.props.match.params.eventId) {
      links.push({
        url: linkToPath(
          this.props.id,
          this.props.match.params.topicId,
          this.props.match.params.eventId
        ),
        name: this.props.eventName,
        id: "breadcrumb-path",
      });
    }

    return (
      <Breadcrumb>
        {links.map((link, index) => {
          return this.convertLinkToBreadcrumb(link, index === links.length - 1);
        })}
      </Breadcrumb>
    );
  }
}

export default connect((store) => {
  return {};
})(withRouter(GlobalBreadcrumb));
