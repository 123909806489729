import React, { Component } from "react";
import { Header } from "semantic-ui-react";
import DynamicImage from "../HelperComponents/DynamicImage";

import "./Footer.scss";

export default class Footer extends Component {

    render() {
        return (
            <div id = "footer" tabIndex="-1" className="ui inverted vertical footer segment">
              <div className="ui container">
                <div className="ui stackable inverted equal height equal width grid sba-links-grid">
                  <div className="column">
                    <Header as="h3" inverted>Customer Service</Header>
                    <div className="ui inverted link list">
                      <a className="item" target="_blank" rel="noopener noreferrer" href="https://www.sba.gov/about-sba">About SBA</a>
                      <a className="item" target="_blank" rel="noopener noreferrer" href="https://www.sba.gov/about-sba/what-we-do/contact-sba">Contact SBA</a>
                      <a className="item" target="_blank" rel="noopener noreferrer" href="https://www.sba.gov/bienvenido-a-la-en-espanol">SBA en Español</a>
                      <a className="item" target="_blank" rel="noopener noreferrer" href="https://www.sba.gov/about-sba/sba-newsroom">Media and Press Relations</a>
                      <a className="item" target="_blank" rel="noopener noreferrer" href="https://www.sba.gov/about-sba/sba-locations">SBA Locations</a>
                      <a className="item" target="_blank" rel="noopener noreferrer" href="https://www.sba.gov/about-sba/sba-team">SBA Team</a>
                    </div>
                  </div>
                  <div className="column">
                    <Header as="h3" inverted>About SBA.gov</Header>
                    <div className="ui inverted link list">
                      <a className="item" target="_blank" rel="noopener noreferrer" href="https://www.sba.gov/sitemap">Site Map</a>
                      <a className="item" target="_blank" rel="noopener noreferrer" href="https://www.sba.gov/about-sba/sba-performance/open-government/about-sbagov-website/privacy-policy">Privacy Policy</a>
                      <a className="item" target="_blank" rel="noopener noreferrer" href="https://www.sba.gov/about-sba/sba-performance/open-government/about-sbagov-website/linking-policy">Linking Policy</a>
                      <a className="item" target="_blank" rel="noopener noreferrer" href="https://www.sba.gov/about-sba/sba-performance/open-government/about-sbagov-website/accessibility">Accessibility</a>
                      <a className="item" target="_blank" rel="noopener noreferrer" href="https://www.sba.gov/about-sba/sba-performance/open-government/about-sbagov-website/disclaimer">Disclaimers</a>
                      <a className="item" target="_blank" rel="noopener noreferrer" href="https://www.sba.gov/tools/events#events-page">Social Media</a>
                      <a className="item" target="_blank" rel="noopener noreferrer" href="https://www.sba.gov/about-sba/sba-performance/open-government/digital-sba/open-data">Data Store</a>
                      <a className="item" target="_blank" rel="noopener noreferrer" href="https://www.sba.gov/blogs">Blog</a>
                    </div>
                  </div>
                  <div className="column">
                    <Header as="h3" inverted>SBA Information</Header>
                    <div className="ui inverted link list">
                      <a className="item" target="_blank" rel="noopener noreferrer" href="https://www.sba.gov/about-sba/sba-performance/open-government/foia">Freedom of Information Act</a>
                      <a className="item" target="_blank" rel="noopener noreferrer" href="https://www.sba.gov/about-sba/sba-performance/open-government/about-sbagov-website/no-fear-act">No Fear Act</a>
                      <a className="item" target="_blank" rel="noopener noreferrer" href="https://www.sba.gov/category/navigation-structure/contracting/contracting-officials/report-fraud-waste-abuse">Report Fraud, Waste, and Abuse</a>
                      <a className="item" target="_blank" rel="noopener noreferrer" href="https://www.sba.gov/about-sba/sba-initiatives">Initiatives</a>
                      <a className="item" target="_blank" rel="noopener noreferrer" href="https://www.sba.gov/about-sba/sba-performance/open-government/other-plans-reports/plain-language-page">Plain Language</a>
                    </div>
                  </div>
                  <div className="column">
                    <Header as="h3" inverted>SBA Performance</Header>
                    <div className="ui inverted link list">
                      <a className="item" target="_blank" rel="noopener noreferrer" href="https://www.sba.gov/about-sba/sba-performance/strategic-planning">Strategic Planning</a>
                      <a className="item" target="_blank" rel="noopener noreferrer" href="https://www.sba.gov/about-sba/sba-performance/performance-budget-finances">Performance, Budget, and Financing</a>
                      <a className="item" target="_blank" rel="noopener noreferrer" href="https://www.sba.gov/about-sba/sba-performance/open-government">Open Government</a>
                      <a className="item" target="_blank" rel="noopener noreferrer" href="https://www.sba.gov/about-sba/sba-performance/policy-regulations">Policy and Regulations</a>
                      <a className="item" target="_blank" rel="noopener noreferrer" href="https://www.sba.gov/about-sba/sba-performance/policy-regulations/eliminating-fraud-waste-abuse">Eliminating Fraud, Waste, and Abuse</a>
                    </div>
                  </div>
                  <div className="column">
                    <Header as="h3" inverted>Oversight</Header>
                    <div className="ui inverted link list">
                      <a className="item" target="_blank" rel="noopener noreferrer" href="https://www.sba.gov/office-of-inspector-general">Inspector General</a>
                      <a className="item" target="_blank" rel="noopener noreferrer" href="https://www.sba.gov/advocacy">Advocacy</a>
                      <a className="item" target="_blank" rel="noopener noreferrer" href="https://www.sba.gov/oha">Hearings and Appeals</a>
                      <a className="item" target="_blank" rel="noopener noreferrer" href="https://www.sba.gov/ombudsman">Ombudsman</a>
                      <a className="item" target="_blank" rel="noopener noreferrer" href="https://www.whitehouse.gov">WhiteHouse.gov</a>
                      <a className="item" target="_blank" rel="noopener noreferrer" href="https://www.usa.gov">USA.gov</a>
                      <a className="item" target="_blank" rel="noopener noreferrer" href="https://www.regulations.gov">Regulations.gov</a>
                    </div>
                  </div>
                  <div className="column">
                    <Header as="h3" inverted>Tools and Features</Header>
                    <div className="ui inverted link list">
                      <a className="item" target="_blank" rel="noopener noreferrer" href="https://www.sba.gov/tools/sba-learning-center/search/training">Online Training</a>
                      <a className="item" target="_blank" rel="noopener noreferrer" href="https://www.sba.gov/tools/business-plan">Create a Business Plan</a>
                      <a className="item" target="_blank" rel="noopener noreferrer" href="https://www.sba.gov/events/find/">Find Events</a>
                      <a className="item" target="_blank" rel="noopener noreferrer" href="https://www.sba.gov/size-standards">Qualify for Government Contracts</a>
                      <a className="item" target="_blank" rel="noopener noreferrer" href="https://www.sba.gov/videos">SBA Videos</a>
                    </div>
                  </div>
                </div>
                <div className="ui inverted grid">
                  <div className="sixteen wide column sba-footer-divider-grid">
                    <div className="ui divider"></div>
                  </div>
                </div>
                <div className="ui inverted grid stackable">
                  <div id="socials" className="eight wide column">
                    <a target="_blank" rel="noopener noreferrer" href="https://facebook.com/SBAgov" ><i role = "img" aria-label="SBA Facebook page"  alt = "SBA Facebook page" className="facebook icon large"></i></a>
                    <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/sbagov" ><i role = "img" aria-label="SBA Twitter page" alt = "SBA Twitter page" className="twitter icon large"></i></a>
                    <a target="_blank" rel="noopener noreferrer" href="https://youtube.com/user/sba" ><i role = "img" aria-label="SBA Youtube page" alt = "SBA Youtube page" className="youtube icon large"></i></a>
                    <a target="_blank" rel="noopener noreferrer" href="https://linkedin.com/company/us-small-business-administration" ><i role = "img" alt = "SBA Linkedin page" aria-label="SBA Linkedin page" className="linkedin icon large"></i></a>
                    <a target="_blank" rel="noopener noreferrer" href="https://instagram.com/sbagov" ><i role = "img" aria-label="SBA Instagram page" alt = "SBA Instagram page" className="instagram icon large"></i></a>
                  </div>
                  <div id="sba-footer-logo-column" className="eight wide column right aligned">
                    <p><a target="_blank" rel="noopener noreferrer" href="https://www.sba.gov" ><DynamicImage alt = "U.S. Small Business Administration" id="sba-footer-logo" size="medium" src="static_content/SBA-Logo-Horizontal-Reverse.png"/></a></p>
                  </div>
                </div>
              </div>
            </div>
            
        );
    }
}
