import React, { Component } from "react";
import ReactPlayer from "react-player";
import { Button, Grid, Header, Divider } from "semantic-ui-react";
import Duration from "../../utils/Duration";
import { getCustomData, rewriteUrlWithCDN } from "../../utils/HelperFunctions";
import LeadInText from "./LeadInText";
import "./LearningEventPodcast.scss";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { completeLearningEvent } from "../../actions/learningEventActions";
import FiresideChatDisclaimer from "../Disclaimers/FiresideChatDisclaimer";

import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import colors from "../../assets/styles/_colors.scss";

export class LearningEventPodcast extends Component {
  state = {
    url: this.props.url,
    playing: false,
    volume: 0.8,
    muted: false,
    pip: false,
    played: 0,
    loaded: 0,
    duration: 0,
    playbackRate: 1.0,
    loop: false,
    height: "100%",
    width: "100%"
  };

  playPause = () => {
    this.setState({ playing: !this.state.playing });
  };

  setVolume = value => {
    this.setState({ volume: parseFloat(value) });
  };

  toggleMuted = () => {
    this.setState({ muted: !this.state.muted });
  };

  onSeekMouseDown = _ => {
    this.setState({ seeking: true });
  };

  onSeekChange = value => {
    this.setState({ seeking: true });
    this.setState({ played: parseFloat(value) });
    this.player.seekTo(parseFloat(value));
    this.setState({ seeking: false });
  };

  onSeekMouseUp = value => {
    this.setState({ seeking: false });
    this.player.seekTo(parseFloat(value));
  };

  onProgress = state => {
    if (!this.state.seeking) {
      this.setState(state);
    }
  };

  onDuration = duration => {
    this.setState({ duration });
  };

  componentDidUpdate(prevProps) {
    const { url } = this.props;

    if (url !== this.state.url) {
      this.setState({ url });
    }
  }

  onEnded = () => {
    const { event, isUserLoggedIn, match } = this.props;

    if (isUserLoggedIn && !event.completed) {
      const { id: path_id, topicId: objective_id } = match.params;

      this.props.dispatch(
        completeLearningEvent(path_id, objective_id, event.id)
      );
    }
  };

  showAuthor = () => {
    const author =  getCustomData(this.props.event, 'author', null);

    if (author !== null) {
      return(
        <span className="podcast-author">by {author}</span>
      )
    }
  }

  showDurationSlider = () => {
    const { played } = this.state;
    return (
      <Slider
        min={0}
        max={1}
        step={0.01}
        value={played}
        onChange={this.onSeekChange}
        className="slider"
        aria-label="seek"
        ariaLabelForHandle="seek bar handle"
        trackStyle={{
          backgroundColor: colors.sbaBlue,
          height: "8px"
        }}
        railStyle={{ height: "8px" }}
        handleStyle={{
          borderColor: colors.borderColor,
          height: 20,
          width: 20
        }}
      />
    )
  }

  showElapsedTime = () => {
    const { duration, played } = this.state;

    return (
      <span className="podcast-duration">
        <Duration seconds={duration * played} />
      </span>
    )
  }

  showDuration = () => {
    const { duration } = this.state;

    return (
      <span className="podcast-duration podcast-duration-right">
        <Duration seconds={duration} />
      </span>
    )
  }

  ref = player => {
    this.player = player;
  };

  render() {
    const {
      url,
      playing,
      volume,
      muted,
      pip,
      playbackRate,
      loop,
      height,
      width
    } = this.state;

    const disclaimerText = this.props.event.disclaimer;

    return (
      <div>
        <div className="podcast-container">
          <div className="podcast-wrapper">
            <ReactPlayer
              ref={this.ref}
              url={rewriteUrlWithCDN(url)}
              pip={pip}
              playing={playing}
              loop={loop}
              playbackRate={playbackRate}
              volume={volume}
              muted={muted}
              height={height}
              width={width}
              onProgress={this.onProgress}
              onDuration={this.onDuration}
              onEnded={this.onEnded}
            />
          </div>
          <div className="controls-container">
            <div className="controls-left">
              <Button
                circular
                basic
                icon={playing ? "pause" : "play"}
                size="massive"
                onClick={this.playPause}
                aria-label={playing ? "pause" : "play"}
              />
            </div>
            <div className="controls-right">
              <Grid>
                <Grid.Column width={16}>
                  <Grid.Row className="podcast-right-column">
                    <Header as="h2"> {this.props.event.title}</Header>
                    { this.showAuthor() }
                  </Grid.Row>
                  <Grid.Row className="mobile-volume-control">
                    <Button
                      circular
                      basic
                      icon={muted ? "volume off" : "volume up"}
                      onClick={this.toggleMuted}
                      aria-label={muted ? "mute" : "unmute"}
                    />
                  </Grid.Row>
                  <Grid.Row className="podcast-right-column">
                    <Grid>
                      <Grid.Row>
                        <Grid.Column className="duration-component" computer={12} tablet={13} mobile={16}>
                          <div className="desktop-duration">
                            { this.showElapsedTime() }
                            { this.showDurationSlider() }
                            { this.showDuration() }
                          </div>
                        </Grid.Column>
                        <Grid.Column className="volume-controls" computer={4} tablet={3}>
                          <Button
                            circular
                            basic
                            icon={muted ? "volume off" : "volume up"}
                            onClick={this.toggleMuted}
                            aria-label={muted ? "mute" : "unmute"}
                          />
                          <Slider
                            min={0}
                            max={1}
                            step={0.01}
                            value={volume}
                            onChange={this.setVolume}
                            className="volume-slider"
                            aria-label="volume slider"
                            ariaLabelForHandle="volume slider handle"
                            trackStyle={{
                              backgroundColor: colors.sbaBlue,
                              height: "8px"
                            }}
                            railStyle={{ height: "8px" }}
                            handleStyle={{
                              borderColor: colors.borderColor,
                              height: 20,
                              width: 20
                            }}
                          />
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Grid.Row>
                </Grid.Column>
              </Grid>
            </div>
          </div>

          <Grid className="podcast-controls">
            <Grid.Column className="mobile-duration" width={16} >
              <Grid>
                <Grid.Row className="mobile-duration-slider">
                  <Grid.Column mobile={16}>
                    { this.showDurationSlider() }
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row className="mobile-duration">
                  <Grid.Column mobile={8}>
                    { this.showElapsedTime() }
                  </Grid.Column>
                  <Grid.Column mobile={8}  textAlign='right'>
                    { this.showDuration() }
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
            <Grid.Column width={16}>
              <Divider />
            </Grid.Column>
          </Grid>

        </div>
        <LeadInText event={this.props.event} />
        { disclaimerText !== null ? <FiresideChatDisclaimer disclaimerText={disclaimerText} /> : null }
      </div>
    );
  }
}

const mapStateToProps = store => {
  return { isUserLoggedIn: store.login.isUserLoggedIn };
};

export default withRouter(connect(mapStateToProps)(LearningEventPodcast));
