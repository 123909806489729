import LeftNavigation from "./LeftNavigation";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

const mapStateToProps = store => {
  return {
    isUserLoggedIn: store.login.isUserLoggedIn
  };
};

export default  withRouter(connect(mapStateToProps)(LeftNavigation));
