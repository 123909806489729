import React, { Component } from "react";
import StyledText from "./StyledText";

import './CenteredText.scss';

export default class CenteredText extends Component {
  render() {
    const { content } = this.props;
    const { supportingText } = content;

    if (supportingText) {
      return <StyledText {...supportingText} />;
    } else {
      return null;
    }
  }
}
