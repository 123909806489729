import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import "../../assets/styles/_globalContent.scss";

import LearningEventHeader from "./LearningEventHeader";
import LearningEventManager from "./LearningEventManager";
import LearningEventFooter from "./LearningEventFooter";
import { Container, Divider } from "semantic-ui-react";
import "./LearningEvent.scss";
import MetaTags from "../SEO/MetaTags";

import {
  getLearningEvent,
  getLearningEventsIfNeeded,
  getFirstLearningEvent
} from "../../actions/learningEventActions";
import MediaTranscriptContent from "./MediaTranscriptContent";
import { getCustomData } from "../../utils/HelperFunctions";
import { linkToPath } from "../../utils/InternalLinks";
import { visitLearningEvent } from "../../actions/learningEventActions";

export class LearningEvent extends Component {
  static getEventPath(course_id, module_id, event_id) {
    return (
      `/${course_id}` +
      `/${module_id}` +
      `/${event_id}`
    );
  }

  componentDidMount() {
    this.setLearningEvent();
  }

  componentDidUpdate(prevProps) {
    const { isUserLoggedIn, match } = this.props;
    const { isUserLoggedIn: prevIsUserLoggedIn, match: prevMatch } = prevProps;

    if (match !== prevMatch || isUserLoggedIn !== prevIsUserLoggedIn) {
      this.setLearningEvent();
    }
  }

  setLearningEvent() {
    const {
      id: course_id,
      topicId: module_id,
      eventId: event_id
    } = this.props.match.params;

    if (event_id === eventProps.first) {
      this.props.dispatch(getFirstLearningEvent(course_id, module_id));
    } else {
      this.props.dispatch(getLearningEventsIfNeeded(course_id, module_id));
      this.props.dispatch(getLearningEvent(course_id, module_id, event_id));
    }
  }

  visitLearningEvent() {
    const path_id = this.props.learningEvent.id;
    const excursion_id = this.props.learningEvent.module_id;
    const journey_id = this.props.journeyId;

    this.props.dispatch(
        visitLearningEvent(journey_id, excursion_id, path_id)
      );
  }

  render() {
    const { learningEvent, topicTitle, journeyHasCertificate } = this.props;
    if (learningEvent.id) {
      this.visitLearningEvent();
    }

    const {
      id: course_id,
      topicId: module_id,
      eventId
    } = this.props.match.params;
    const learningEvents = this.props.learningEventsCollection[course_id]
      ? this.props.learningEventsCollection[course_id][module_id]
      : [];

    return (
      <div className="learning-event-wrapper">
        <Container className="learning-event-container">
          <MetaTags
            metaTitle={learningEvent.title}
            metaDescription={getCustomData(learningEvent, "description", null)}
            metaImage={getCustomData(learningEvent, "thumbnail_url", null)}
            canonicalUrl={`${process.env.REACT_APP_SITE_ROOT}${linkToPath(course_id, module_id, eventId)}`}
          />
          <LearningEventHeader event={learningEvent} topicTitle={topicTitle} />
          <LearningEventManager
            event={learningEvent}
            courseId={this.props.match.params.id}
            journeyHasCertificate={journeyHasCertificate}
          />
          <Divider className="event-footer-divider" />
          <LearningEventFooter
            courseId={course_id}
            module={learningEvents}
            event={learningEvent}
            excursionId={module_id}
          />
        </Container>
        <MediaTranscriptContent />
      </div>
    );
  }
}

const eventProps = {
  first: "first"
};

const mapStateToProps = store => {
  return {
    learningEvent: store.learningEvent.learningEvent,
    learningEventsCollection: store.learningEvent.learningEventsCollection,
    isUserLoggedIn: store.login.isUserLoggedIn,
    journeyId: store.journey.journey.id,
    journeyHasCertificate: store.journey.journey.has_completion_certificate
  };
};

export default withRouter(connect(mapStateToProps)(LearningEvent));
