import React, { Component } from "react";
import { Grid } from "semantic-ui-react";
import "./RegistrationComplete.scss";
import DynamicImage from "../HelperComponents/DynamicImage";

export default class RegistrationComplete extends Component {
  render() {
    return (
      <Grid id="registration-complete" centered columns={16}>
        <Grid.Row centered columns={4}>
          <DynamicImage
            className="logo-congrats"
            src="static_content/modal-graphic-2x.png"
            alt="Ascent"
            centered
          />
        </Grid.Row>
        <Grid.Row className="success-message" centered columns={16}>
          <Grid.Column width={8}>
            <h2>Congrats,</h2>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="success-message" centered columns={14}>
          <Grid.Column width={12}>
            <h2>You've successfully registered!</h2>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row centered columns={16}>
          <Grid.Column width={12} className="verify-your-email">
            <span>
              Please take a look at your inbox to verify your email and begin
              your Journey!
            </span>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}
