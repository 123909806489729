import React, { Component } from "react";
import ParsedHtml from "../../../HelperComponents/ParsedHtml";

export default class StyledText extends Component {
  render() {
    const { content } = this.props;
    const style = this.props.style || {};
    const className = style.className || "";
    const innerStyle = style.innerStyle;

    if (content) {
      return (
        <div style={innerStyle} className={`styled-text ${className}`}>
          <ParsedHtml html={content.text} />
        </div>
      );
    } else {
      return null;
    }
  }
}
