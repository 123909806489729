import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Grid, Icon, List } from "semantic-ui-react";
import PathList from "./PathList";
import LeftNavigationPlaceholder from "./LeftNavigationPlaceholder";
import "./LeftNavigation.scss";

export class LeftNavigation extends Component {
  renderNumberCircle(idx, activeIndex, excursion) {
    const activeClass =
      activeIndex === excursion.id.toString() ? " active-nav-item" : "";

    var excursionProgress = {};

    try {
      excursionProgress = this.props.excursionProgress[excursion.id] || {};
    } catch(e) { }

    const completeClass = excursionProgress.completed
      ? " completed-excursion"
      : "";
    const circleIcon = excursionProgress.completed ? (
      <Icon name="checkmark" />
    ) : (
      idx + 1
    );

    return (
      <div className={`topic-number-circle-wrapper${activeClass}`}>
        <div className={`topic-number-circle${completeClass}`}>
          <div className="topic-number-circle-text">{circleIcon}</div>
        </div>
      </div>
    );
  }

  renderPathList(journey_id, path_id, excursion) {
    if (path_id) {
      return (
        <List.Item className="ln-excursion-content">
          <PathList
            course_id={journey_id}
            module_id={excursion.id}
            event_id={path_id}
          />
        </List.Item>
      );
    }
  }

  getProgress(excursion) {
    const isUserLoggedIn = this.props.isUserLoggedIn;
    let completedPaths = 0;
    let total = excursion.total_paths || 0;

    var excursionProgress = {};

    try {
      excursionProgress = this.props.excursionProgress[excursion.id] || {};
    } catch(e) { }

    if (isUserLoggedIn) {
      completedPaths = excursionProgress.requirement_completed_count || 0;
      return "(" + completedPaths + "/" + total + ")";
    } else {
      return "(" + total + ")";
    }
  }

  renderExcursionList(journey) {
    const { path_id } = this.props;
    const { topicId } = this.props.match.params;
    const journey_id = journey.id;
    const excursions = journey.excursions;
    const topicPathActive = topicId && path_id ? " topic-path-active" : "";

    if (excursions && excursions.length) {
      return (
        <Container
          fluid
          className={`left-navigation-container${topicPathActive}`}
        >
          <List>
            {excursions.map((excursion, idx) => {
              let activeExcursion = topicId
                ? topicId
                : excursions[0].id.toString();

              if (
                !path_id ||
                (path_id &&
                  activeExcursion.toString() === excursion.id.toString())
              ) {
                return (
                  <div role="listitem" key={"leftNavigation" + idx}>
                    <Link to={`/${journey_id}/${excursion.id}`}>
                      <List.Header index={idx} className="ln-excursion-title">
                        <Grid>
                          <Grid.Row
                            verticalAlign="middle"
                            className="left-nav-item"
                          >
                            <Grid.Column width={3}>
                              {this.renderNumberCircle(
                                idx,
                                activeExcursion,
                                excursion
                              )}
                            </Grid.Column>
                            <Grid.Column width={11}>
                              <div className="excursion-name">
                                <div>{excursion.name}</div>
                              </div>
                            </Grid.Column>
                            <Grid.Column width={2}>
                              <div className="excursion-name-progress">
                                {this.getProgress(excursion)}
                              </div>
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                      </List.Header>
                    </Link>
                    {this.renderPathList(journey_id, path_id, excursion)}
                  </div>
                );
              } else {
                return "";
              }
            })}
          </List>
        </Container>
      );
    }
    return null;
  }

  render() {
    return this.props.pathTopicsLoading ? (
      <LeftNavigationPlaceholder />
    ) : (
      this.renderExcursionList(this.props.journey)
    );
  }
}

export default LeftNavigation;
