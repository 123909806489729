import React, { Component } from "react";
import { connect } from "react-redux";
import LearningEvent from "./LearningEvent";
import { Grid } from 'semantic-ui-react'
import {
  EventNextButton,
  EventPrevButton
} from "./LearningEventButtons/EventFooterButtons";
import MediaTranscriptMenu from "./MediaTranscriptMenu";

export class LearningEventFooter extends Component {
  nextPrevButtons(modules, event) {
    var currentEventPosition = modules.findIndex(element => element.id === event.id) + 1

    let nextButton,
      prevButton = null;
    if (event && currentEventPosition && modules) {
      const pos = currentEventPosition - 1; //To account for zero based array indexes
      const { courseId, excursionId, pathsComplete, pathsTotal, isUserLoggedIn } = this.props;

      if (currentEventPosition === modules.length) {
        let url = `/${courseId}`;

        // If user is logged in and all excursions are completed, user is returned to dashboard
        if (isUserLoggedIn) {
          if (pathsComplete === pathsTotal) {
            url = '/dashboard';
          }
        }

        //This is the last event so we display a finish button
        nextButton = <EventNextButton url={url} text="Finish" />;
      } else if (modules[pos + 1]) {
        const url = LearningEvent.getEventPath(
          courseId,
          excursionId,
          modules[pos + 1].id
        );

        //We have more events so we display a next button
        nextButton = <EventNextButton url={url} text="Next" />;
      }

      if (modules[pos - 1]) {
        const url = LearningEvent.getEventPath(
          courseId,
          excursionId,
          modules[pos - 1].id
        );
        prevButton = <EventPrevButton url={url} />;
      }
    }
    return { nextButton, prevButton };
  }

  render() {
    const { event, module: modules } = this.props;
    const { nextButton, prevButton } = this.nextPrevButtons(modules, event);
    const screenReaderInformation = <span className="screenreader-only">Additional information is on the page after the navigation buttons.</span>
    const audioOrVideo = event.type === "ExternalUrl";

    return (
        <Grid className="learning-event-footer" reversed="tablet computer">
          <Grid.Row>
            <Grid.Column className="learning-event-navigation-wrapper" mobile={16} tablet={16} computer={8}>
              {audioOrVideo ? screenReaderInformation : null}{prevButton} {nextButton}
            </Grid.Column>
            <Grid.Column className="transcription-navigation-wrapper" mobile={16} tablet={16} computer={8}>
              <MediaTranscriptMenu />
            </Grid.Column>
          </Grid.Row>
        </Grid>

    );
  }
}

const mapStateToProps = store => {
  return {
    pathsComplete: store.journey.pathsComplete,
    pathsTotal: store.journey.pathsTotal,
    isUserLoggedIn: store.login.isUserLoggedIn
  };
};

export default connect(mapStateToProps)(LearningEventFooter);
