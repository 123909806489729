import React, { Component } from "react";
import HomepageBlockManager from "./HomepageBlocks/HomepageBlockManager";
import MetaTags from "../SEO/MetaTags";

import { getHomepageContent } from "../../actions/homepageActions";
import { connect } from "react-redux";

import "./Homepage.scss";

export class Homepage extends Component {
  componentDidMount() {
    this.props.getHomepageContent();
  }

  renderMetadata(data) {
    const props = data || {};
    const metadata = props.metadata || {};
    const defaultDescription =
      "Welcome to Ascent where you will find the resources you need to set and achieve your business goals";
    const siteName = process.env.REACT_APP_SITE_NAME || "Ascent";

    return (
      <MetaTags
        metaTitle={metadata.title || siteName}
        metaDescription={metadata.description || defaultDescription}
        canonicalUrl={process.env.REACT_APP_SITE_ROOT}
      />
    );
  }

  renderContainers(data) {
    if (!data || !data.blocks || !data.blocks.map) {
      return null;
    }

    return data.blocks.map((container, index) => {
      return (
        <HomepageBlockManager
          {...container}
          containerIndex={index}
          key={`homepage-container-${index}`}
        />
      );
    });
  }

  render() {
    return (
      <div id="homepage" className="homepage">
        {this.renderMetadata(this.props.homepage)}
        {this.renderContainers(this.props.homepage)}
      </div>
    );
  }
}

const mapStateToProps = (store) => ({
  homepage: store.homepage,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getHomepageContent: () => dispatch(getHomepageContent()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Homepage);
