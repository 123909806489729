import React from "react";
import { connect } from "react-redux";
import FocusLock from "react-focus-lock";
import { externalSite } from "../../actions/globalActions";
import { Modal, Grid, Button, Icon, Header, Divider } from "semantic-ui-react";
import DynamicImage from "../HelperComponents/DynamicImage";
import get from "lodash/get";

import "./ExternalSiteModal.scss";

export class ExternalSiteModal extends React.Component {
  close = () => {
    this.props.dispatch(externalSite(false, null));
  };

  renderImage(image) {
    if (image) {
      return (
        <DynamicImage
          src={image.src}
          alt={image.alt}
          size={image.size}
          centered
        />
      );
    }
  }

  render() {
    const image = get(this.props, "data.image", null);
    const { headerText, bodyText } = get(this.props, "data", {});

    return (
      <Modal
        id="external-site-modal"
        open={this.props.externalSite}
        closeOnDimmerClick={false}
        aria-modal="true"
        tabIndex="-1"
        onClose={this.close}
        size={"tiny"}
      >
        <FocusLock returnFocus={true} className="content">
            <Grid>
              <Grid.Row>
                <Grid.Column width={2} />
                <Grid.Column width={12} textAlign="center">
                  {this.renderImage(image)}
                </Grid.Column>
                <Grid.Column width={2} textAlign="right">
                  <Button
                    className="eli-modal-close-button"
                    aria-label="Close"
                    onClick={this.close}
                    icon
                  >
                    <Icon link name="close" size="large" />
                  </Button>
                </Grid.Column>
              </Grid.Row>

              <Grid.Row id="header-row">
                <Grid.Column width={16} textAlign="center">
                  <Header as="h2">{headerText}</Header>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row centered id="header-divider-row">
                <Grid.Column centered width={6} textAlign="center">
                  <Divider />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row id="disclaimer-text-row">
                <Grid.Column width={16} textAlign="center">
                  {bodyText}
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={16} textAlign="center">
                  <span id="link-text-header">Link to Website:</span>
                  <br />
                  <a
                    href={this.props.externalUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={this.close}
                  >
                    {this.props.externalUrl}
                  </a>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={16} textAlign="center">
                  <Button
                    primary
                    centered
                    as="a"
                    href={this.props.externalUrl}
                    target="_blank"
                    aria-label="Link to external site"
                    rel="noopener noreferrer"
                    onClick={this.close}
                    data-autofocus
                  >
                    Continue
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
        </FocusLock>
      </Modal>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    externalSite: store.global.externalSite,
    externalUrl: store.global.externalUrl,
    data: store.json.externalSiteModal,
  };
};

export default connect(mapStateToProps)(ExternalSiteModal);
