import loadJSON from "../utils/LoadJSON";

function defaultState() {
  const siteName = process.env.REACT_APP_SITE_NAME;

  return {
    navLogo: loadJSON("navLogo", siteName),
    navLogoLink: loadJSON("navLogoLink", siteName),
    dashboardTitle: loadJSON("dashboardTitle", siteName),
    dashboardDescription: loadJSON("dashboardDescription", siteName),
    journeysTitle: loadJSON("journeysTitle", siteName),
    journeysDescription: loadJSON("journeysDescription", siteName),
    progressPicture: loadJSON("progressPicture", siteName),
    externalSiteModal: loadJSON("externalSiteModal", siteName),
    journeyTerm: loadJSON("journeyTerm", siteName) || "Journey",
    excursionTerm: loadJSON("excursionTerm", siteName) || "Excursion",
    pathTerm: loadJSON("pathTerm", siteName) || "Path"
  };
}

export default function reducer(state = defaultState(), action) {
  switch (action.type) {
    default:
      break;
  }

  return state;
}
