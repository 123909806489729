import React, { Component } from "react";
import { Button, Container, Form, Grid, Input } from "semantic-ui-react";
import { requestPasswordReset } from "../../actions/registrationActions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import "./ForgotPasswordForm.scss";

export class ForgotPasswordForm extends Component {
  state = {
    email: '',
    password: ''
  };
  handleRef = component => (this.ref = component);

  handleEmailChange = (e) => {
    this.setState({email: e.target.value});
  }

  handlePasswordChange = (e) => {
    this.setState({password: e.target.value});
  }

  handleSubmit = (e) => {
    e.preventDefault();

    this.props.dispatch(requestPasswordReset({
      email: this.state.email
    }));
  }

  componentDidMount() {
    if (this.ref) {
      this.ref.focus();
    }
  }

  // User will see same response msg regardless of success or failure
  returnUserMessageIfNeeded(onPasswordSubmit) {
    if ((this.props.userError) || (onPasswordSubmit)) {
      return (
        <Grid centered>
          <Grid.Row>
            <Grid.Column textAlign="left" className="ui success message">
              A password reset link will be emailed to {this.state.email}.
            </Grid.Column>
          </Grid.Row>
        </Grid>
      );
    }
  }

  getPasswordFormOrResetMessage(onPasswordSubmit) {
      return (
        <Container className="reset-modal">
          {this.returnUserMessageIfNeeded(onPasswordSubmit)}
          <Form onSubmit={this.handleSubmit.bind(this)}>
            <Grid centered>
              <Grid.Row>
                <Grid.Column textAlign="left">
                  Enter the email address associated with this account. Follow instructions in the email in order to reset your password.
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Form.Field>
              <label>Email:</label>
              <Input type="text"
                     value={this.state.email}
                     aria-label="Email"
                     ref={this.handleRef}
                     placeholder="Email"
                     onChange={this.handleEmailChange.bind(this)}/> <br />
            </Form.Field>
            <Grid>
              <Grid.Row>
                <Grid.Column textAlign="center">
                  <Button className="secondary" aria-label="Submit" type="submit">Reset Password</Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        </Container>
      );
  }

  render() {
    return (
      <Container>
        {this.getPasswordFormOrResetMessage(this.props.onPasswordSubmit)}
      </Container>
    );
  }
}

const mapStateToProps = store => {
  return {
    email: store.registration.userData.email,
    password: store.registration.userData.password,
    userError: store.registration.userError,
    onPasswordSubmit: store.registration.onPasswordSubmit
  };
};

export default withRouter(connect(mapStateToProps)(ForgotPasswordForm))
