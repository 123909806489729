import React, { Component } from "react";
import ExcursionPathList from "./ExcursionPathList";
import { Item, Button, Icon, Accordion } from "semantic-ui-react";
import { getCustomData } from "../../utils/HelperFunctions";
import { ImagePlaceholder, rewriteUrlWithCDN } from "../../utils/HelperFunctions";
import ExpandCollapseText from "../HelperComponents/ExpandCollapseText";
import { connect } from "react-redux";

export class TopicContentItem extends Component {
  state = {
    defaultDescriptionLength: 550,
    listVisible: true,
    activeIndex: 0
  };

  //Using this as a collapse element, no other option in semantic. Open sets the index of accordion
  //to only element, -1 is closed.
  toggleListVisibility = () => {
    const listVisible = !this.state.listVisible;
    this.setState({ listVisible });

    if (listVisible) {
      this.setState({ activeIndex: 0 });
    } else {
      this.setState({ activeIndex: -1 });
    }
  };

  getTopicThumbnail(topic) {
    return rewriteUrlWithCDN(getCustomData(topic, "thumbnail_url", ImagePlaceholder()));
  }

  render() {
    const { topic, course_id } = this.props;
    const { listVisible, activeIndex, defaultDescriptionLength } = this.state;
    const description = getCustomData(topic, "description", "");
    const longDescription = getCustomData(topic, "longDescription", description);
    const pathTerm = this.props.pathTerm.toLowerCase();

    return (
      <React.Fragment>
        <Item.Group className="topic-content-item-group">
          <Item className="topic-content-item">
            <Item.Image
              src={this.getTopicThumbnail(topic)}
              className="computer large screen widescreen only"
              alt={getCustomData(topic, "thumbnail_url_alt", "")}
            />

            <Item.Content>
              <Item.Header as="h2" className="topic-content-item-header">
                {topic.name}
              </Item.Header>
              <Item.Meta>
                {topic.total_paths || 0} {pathTerm}{topic.total_paths === 1 ? '' : 's'} (
                {getCustomData(topic, "time", "unknown")})
              </Item.Meta>
              <Item.Description>
                <ExpandCollapseText
                  text={longDescription}
                  defaultTextLength={defaultDescriptionLength}
                />
              </Item.Description>

              <Button
                icon
                className="topic-content-collapse-button mobile only"
                aria-label={listVisible ? "collapse" : "expand"}
                onClick={this.toggleListVisibility}
              >
                <Icon name={listVisible ? "chevron up" : "chevron down"} />
              </Button>
            </Item.Content>
          </Item>
        </Item.Group>

        <Accordion>
          {/* Title is a hidden element, needed for accordion collapse to function */}
          <Accordion.Title
            className="events-list-accordion-title"
            active={activeIndex === 0}
            index={0}
          >
            Learning Events for {topic.name}
          </Accordion.Title>
          <Accordion.Content active={activeIndex === 0}>
            <ExcursionPathList course_id={course_id} module_id={topic.id} />
          </Accordion.Content>
        </Accordion>
      </React.Fragment>
    );
  }
}

const mapStateToProps = store => {
  return {
    pathTerm: store.json.pathTerm
  };
};

export default connect(mapStateToProps)(TopicContentItem);
