import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  getCustomData,
  getTimeLabel,
  StripHtmlTags
} from "../../utils/HelperFunctions";
import { getLearningEvents, getLearningEventsIfNeeded } from "../../actions/learningEventActions";
import CheckmarkCompleted from '../CheckmarkCompleted/CheckmarkCompleted';
import { isPathComplete } from '../../utils/PathProgress';
import DynamicImage from "../HelperComponents/DynamicImage";
import ParsedHtml from "../HelperComponents/ParsedHtml";

import './ExcursionPathList.scss';

export class ExcursionPathList extends Component {
  componentDidMount() {
    this.eventsList();
  }

  componentDidUpdate(prevProps) {
    if (this.props.isUserLoggedIn !== prevProps.isUserLoggedIn) {
      this.props.dispatch(
        getLearningEvents(this.props.course_id, this.props.module_id)
      );
    }
  }

  eventsList() {
    this.props.dispatch(
      getLearningEventsIfNeeded(this.props.course_id, this.props.module_id)
    );
  }

  showThumbnailImage(pathType) {
    const typesToHide = ["Key Takeaway"];

    return !typesToHide.find(type => type === pathType);
  }

  getThumbnail(event){
    const defaultURL = '/Platform+Graphics/Case_Study.png';
    const url = getCustomData(event, "thumbnail_url", defaultURL);
    const imageAlt = getCustomData(event, "thumbnail_url_alt", "")

    return (
      <div className="icon-column">
        <DynamicImage
          className="topic-content-event-grid-icon"
          src={url}
          alt={imageAlt}
        />
      </div>
    )
  }

  renderEventList(eventList) {
    const { course_id, module_id } = this.props;
    const url = `/${course_id}/${module_id}/`;

    if (eventList && eventList.length) {
      return eventList.map(event => {
        const isComplete = isPathComplete(event.id, this.props.pathProgress);
        const eventType = getCustomData(event, "event_type", event.type);

        return (
          <div
            className="topic-content-event-grid"
            key={"topicContentItem" + event.id}
          >
            {this.showThumbnailImage(eventType)
              ? this.getThumbnail(event)
              : null}

            <div className="event-content-column">
              <div className="event-title-container">
                <Link
                  className="topic-content-event-grid-header"
                  to={url + event.id}
                >
                  {event.title}
                </Link>
                {isComplete && <CheckmarkCompleted/>}
              </div>
              <div className="topic-content-event-grid-meta">
                <div className="event-type">
                  {getCustomData(event, 'event_type', 'Article')} {getTimeLabel(event)}
                </div>
                <p><ParsedHtml html={StripHtmlTags(getCustomData(event, 'description', ''))} /></p>
              </div>
            </div>
          </div>
        );
      });
    } else {
      return null;
    }
  }

  render() {
    const { module_id, course_id } = this.props;
    const learningEvents = this.props.learningEventsCollection[course_id]
      ? this.props.learningEventsCollection[course_id][module_id]
      : [];

    return this.renderEventList(learningEvents);
  }
}

const mapStateToProps = store => {
  return {
    learningEventsCollection: store.learningEvent.learningEventsCollection,
    pathProgress: store.learningEvent.progress,
    isUserLoggedIn: store.login.isUserLoggedIn
  };
};

export default connect(mapStateToProps)(ExcursionPathList);
