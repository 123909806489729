import React from "react";

import "./HomepageContainer.scss";
import { Grid } from "semantic-ui-react";
import HomepageBlockManager from "./HomepageBlocks/HomepageBlockManager";
import StyledText from "../LearningEvent/InfographicTemplate/Blocks/StyledText";

export class HomepageContainer extends React.Component {
  getTitleRow() {
    if (!this.props.content.title) {
      return null;
    }
    return (
      <Grid.Row centered>
        <Grid.Column computer={16} mobile={14} textAlign="center">
          <StyledText {...this.props.content.title}/>
        </Grid.Column>
      </Grid.Row>
    );
  }

  getSubtitleRow() {
    if (!this.props.content.subTitle) {
      return null;
    }
    return (
      <Grid.Row centered>
        <Grid.Column width={14} textAlign="center">
          <StyledText {...this.props.content.subTitle}/>
        </Grid.Column>
      </Grid.Row>
    );
  }

  renderBlocks(blocks) {
    const { containerIndex } = this.props;
    return blocks.map((block, index) => {
      return (
        <HomepageBlockManager
          block={block}
          key={`homepage-block-${containerIndex}.${index}`}
        />
      );
    });
  }

  render() {
    const extendedClasses = this.props.className || "";
    const style = this.props.content.style || {};

    return (
      <div className={extendedClasses} style={style}>
        <div className="homepage-container">
          <div className="homepage-container-title">
            <Grid>
              {this.getTitleRow()}
              {this.getSubtitleRow()}
            </Grid>
          </div>
          {this.renderBlocks(this.props.content.blocks || [])}
        </div>
      </div>
    );
  }
}

export default HomepageContainer;
