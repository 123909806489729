import React, { Component } from "react";
import InfographicBlockManager from "./InfographicBlockManager";

export default class InfographicBlocks extends Component {
  render() {
    const blocks = this.props.blocks || [];
    return blocks.map((block, index) => {
      return <InfographicBlockManager block={block} key={"block " + index} />;
    });
  }
}
