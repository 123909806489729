import React, { Component } from "react";
import { Grid, Button } from "semantic-ui-react";
//import "./RegistrationComplete.scss";
import DynamicImage from "../HelperComponents/DynamicImage";

export default class DemographicSuccess extends Component {
  render() {
    return (
      <Grid id="registration-complete" centered columns={16}>
        <Grid.Row centered columns={10}>
          <DynamicImage
            className="logo-congrats"
            src="static_content/modal-graphic-2x.png"
            alt="Ascent"
            centered
          />
        </Grid.Row>
        <Grid.Row centered columns={16}>
          <Grid.Column width={12} className="verify-your-email">
            <Button
              aria-label="Get started!"
              className="primary"
              onClick={this.props.closeModal.bind(this)}
            >
              Get started now!
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}