import React from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { updateDemographicDetails } from '../../actions/demographicActions';
import NumericQuestionWithLabel from '../NumericQuestionWithLabel/NumericQuestionWithLabel';
import OptionalForm from './OptionalForm';
import GriddedQuestion from './GriddedQuestion';
import CheckboxQuestion from '../CheckboxQuestion/CheckboxQuestion';

export class BusinessGoalsForm extends React.Component {
  updateState(key, value) {
    this.props.dispatch(updateDemographicDetails(key, value));
  }

  render() {
    return (
      <OptionalForm prevButton={true} registerButton={true}>
        <GriddedQuestion number="8">
          <NumericQuestionWithLabel small text="In twelve months, my goal is to increase my revenue by"
                        value={this.props.revenue_goal} options={["percent (%)", "dollars ($)"]}
                        onChange={(value) => this.updateState("revenue_goal", value)}
                        autoFocus={true}/>
        </GriddedQuestion>
        <GriddedQuestion number="9">
          <NumericQuestionWithLabel text="In twelve months, my goal is to increase the number of employees by"
                        value={this.props.employment_goal} options={["percent (%)", "number of employees (#)"]}
                        onChange={(value) => this.updateState("employment_goal", value)}/>
        </GriddedQuestion>
        <GriddedQuestion number="10">
          <CheckboxQuestion label="Are you certified for government contracting" onlyAllowOne={true}
                            answers={["Yes", "No", "I don't know what that means"]}
                            value={this.props.certified_for_contracting}
                            onChange={(value) => this.updateState("certified_for_contracting", value)} />
        </GriddedQuestion>
        <GriddedQuestion number="11">
          <CheckboxQuestion label="What type of certifications do you hold"
                            answers={["Small Business (self certification)", "WOSB or EDWOSB (SBA certification)", "HZ (SBA certification)", "8a (SBA certification)", "VET (SBA certification)", "SDVOSB (SBA certification)"]}
                            value={this.props.certifications}
                            onChange={(value) => this.updateState("certifications", value)}/>
        </GriddedQuestion>
        <GriddedQuestion number="12">
          <CheckboxQuestion label="Are you currently procuring through the federal government" onlyAllowOne={true}
                            answers={["Yes", "No", "I don't know what that means"]}
                            value={this.props.current_procuring}
                            onChange={(value) => this.updateState("current_procuring", value)} />
        </GriddedQuestion>
      </OptionalForm>
    )
  }
}

const mapStateToProps = store => {
  return {
    revenue_goal: store.demographic.demographicDetails.revenue_goal,
    employment_goal: store.demographic.demographicDetails.employment_goal,
    certified_for_contracting: store.demographic.demographicDetails.certified_for_contracting,
    certifications: store.demographic.demographicDetails.certifications,
    current_procuring: store.demographic.demographicDetails.current_procuring,
  }
}

export default withRouter(connect(mapStateToProps)(BusinessGoalsForm))
