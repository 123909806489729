import React, { Component } from "react";
import "./LearningEventPageContent.scss";
import {
  Button,
  Divider,
  Form
} from "semantic-ui-react";
import { completeLearningEvent } from "../../actions/learningEventActions";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import ParsedHtml from "../HelperComponents/ParsedHtml";
import JourneyProgress from "../JourneyResumeComponent/JourneyProgress";

export class LearningEventPage extends Component {
  state = this.getState();

  getState() {
    return {
      name_for_certificate: ""
    };
  }


  componentDidMount() {
    this.completeEvent();
  }

  componentDidUpdate(prevProps) {
    if (this.props.event !== prevProps.event) {
      this.completeEvent();
    }
  }

  completeEvent() {
    const { isUserLoggedIn, event } = this.props;
    if (isUserLoggedIn && !event.completed) {
      const { id: path_id, topicId: objective_id } = this.props.match.params;

      this.props.dispatch(
        completeLearningEvent(path_id, objective_id, event.id)
      );
    }
  }

  journeyProgress() {
    const complete = this.props.pathsComplete;
    const total = this.props.pathsTotal;
    if (complete !== null && total != null) {
      return <JourneyProgress complete={complete} total={total} />;
    }
  }

  getChangeHandler(field) {
    return (e) => {
      var state = {};
      state[field] = e.target.value;
      this.setState(state);
    };
  }

  checkState(stateName) {
    try {
      return this.state[stateName].trim();
    } catch {
      return "";
    }
  }

  hasValue(value) {
    return value.length > 0;
  }

  submitEnabled() {
    return(
      this.hasValue(this.state.name_for_certificate)
    )
  }

  handleSubmit = (e) => {
    e.preventDefault();

    window.alert(this.checkState("name_for_certificate"))
  }

  renderCertificate() {
    const { event, courseId, journeyHasCertificate, journeyComplete} = this.props;
    if (journeyHasCertificate && event.custom_data.event_type === "Key Takeaway") {
      if (journeyComplete) {
        return(
          <div>
            <Divider />
            <Form onSubmit={this.handleSubmit.bind(this)}>
              <Form.Field required={true}>
                <label>Name for Certificate</label>
                <input
                  placeholder="Name for Certificate"
                  type="text"
                  name="name_for_certificate"
                  aria-label="Name for Certificate"
                  value={this.state["name_for_certificate"]}
                  onChange={this.getChangeHandler("name_for_certificate").bind(this)}
                  error={"false"}
                />
              </Form.Field>
              <Form.Group grouped>
                <Button
                  aria-label="Submit"
                  className="primary"
                  size="large"
                  href={encodeURI(process.env.REACT_APP_SERVICE_HOST + "/journeys/" + courseId + "/certificate?name_on_certificate=" + this.state.name_for_certificate)}
                  disabled={!this.submitEnabled()}
                >
                  Print Certificate
                </Button>
              </Form.Group>
            </Form>
          </div>
        )
      } else {
        return(
          <div>
            <Divider />
              <p class="not-completed-message">
                <span>{`You must complete all ${this.props.pathTerm.toLowerCase()}s before printing your certificate!`}</span>
              </p>
            { this.journeyProgress() }
          </div>
        )
      }
    }
  }

  render() {
    const __html = this.props.event.eventContent.body;
    return (
      <div className="learning-event-body">
        <ParsedHtml html={__html} />
        { this.renderCertificate() }
      </div>
    );
  }
}

const mapStateToProps = store => {
  return {
    isUserLoggedIn: store.login.isUserLoggedIn,
    journeyComplete: store.journey.journeyComplete,
    pathsTotal: store.journey.pathsTotal,
    pathsComplete: store.journey.pathsComplete,
    journey: store.journey.journey,
    pathTerm: store.json.pathTerm
  };
};

export default withRouter(connect(mapStateToProps)(LearningEventPage));
