import React, { Component } from "react";
import { Header, Divider, Grid } from "semantic-ui-react";
import DownloadButton from "./DownloadButton";
import PrintButton from "./PrintButton";
import { getCustomData, getTimeLabel, rewriteUrlWithCDN } from "../../utils/HelperFunctions";
import "./LearningEvent.scss";

class LearningEventHeader extends Component {
  showDownloadButton(event) {
    if (event.type === "File") {
      return <DownloadButton url={rewriteUrlWithCDN(event.eventContent.url)} />;
    }
    if (event.type === "ExternalUrl") {
      return <DownloadButton url={rewriteUrlWithCDN(event.external_url)} />;
    }
    return null;
  }

  showPrintButton(event) {
    if (event.type === "InfographicTemplate") {
      return <PrintButton />;
    }
  }

  showSubtitle(event) {
    const subtitle = event.subtitle;

    if (subtitle) {
      return (
        <Grid.Row className="header-row">
          <Grid.Column>
            <Header className="event-sub-title" as="h2">
              {subtitle}
            </Header>
          </Grid.Column>
        </Grid.Row>
      );
    }
  }

  render() {
    const { event, topicTitle } = this.props;
    return (
      <Grid className="learning-event-header">
        <Grid.Row className="header-row">
          <Grid.Column>
            <div className="topic-title">{topicTitle}</div>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="header-row">
          <Grid.Column>
            <Header className="event-title" as="h1">
              {event.title}
            </Header>
          </Grid.Column>
        </Grid.Row>
        {this.showSubtitle(event)}
        <Grid.Row className="header-row">
          <Grid.Column width={12}>
            <div className="event-meta">
              {getCustomData(event, "event_type", "Article")}{" "}
              {getTimeLabel(event)}
            </div>
          </Grid.Column>
          <Grid.Column width={4}>
            {this.showDownloadButton(event)}
            {this.showPrintButton(event)}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="header-row">
          <Grid.Column>
            <Divider />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export default LearningEventHeader;
