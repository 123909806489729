import React, { Component } from "react";
import { connect } from "react-redux";
import { Grid, Header } from "semantic-ui-react";
import { Link, withRouter } from "react-router-dom";
import GlobalBreadcrumb from "./GlobalBreadcrumb";
import ShareModal from "../ShareModal/ShareModal";
import PropTypes from "prop-types";
import { linkToDashboard, linkToJourney } from "../../utils/InternalLinks";
import { isHomepageEnabled } from "../../utils/EnvironmentFunctions";
import { getCustomData, getJourneyName } from "../../utils/HelperFunctions";


import "./BreadcrumbHeader.scss";

export class BreadcrumbHeader extends Component {
  shareModal(journey) {
    if (journey && journey.name) {
      return (

          <ShareModal
            contentType={this.props.journeyTerm}
            title={getJourneyName(journey)}
            description={journey.description}
            location={window.location.href}
          />

      );
    }
  }

  headerContent(journey, headerText) {
    if (journey && journey.id && journey.name) {
      return <Link to={linkToJourney(journey.id)}>{getJourneyName(journey)}</Link>;
    } else {
      return headerText;
    }
  }

  descriptionRow() {
    const description = getCustomData(this.props.journey, "description", null);
    if (description && !this.props.isMobile) {
      return <div className="description-row">{description}</div>;
    }
  }

  renderBreadcrumb() {
    const {
      pathName,
      topicName,
      eventName,
      isMobile,
      location
    } = this.props;

    if(isHomepageEnabled() || (location.pathname !== linkToDashboard()) ) {
      return(
        <GlobalBreadcrumb
          pathName={pathName}
          topicName={topicName}
          eventName={eventName}
          isMobile={isMobile}
        />
      )
    }
  }

  render() {
    const {
      isMobile,
      journey,
      headerText
    } = this.props;

    return (
      <Grid centered className="breadcrumb-header-grid">
        <Grid.Row className="breadcrumb-header-row" verticalAlign="middle">
          <Grid.Column mobile={12} tablet={12} computer={12}>
            <div className="breadcrumb-row">
              { this.renderBreadcrumb() }
            </div>
            <div className="header-row">
              <Header as="div" className="header-content">
                {isMobile ? null : this.headerContent(journey, headerText)}
              </Header>
            </div>
            {this.descriptionRow()}
          </Grid.Column>
          <Grid.Column className="share-button-wrapper" mobile={3} tablet={3} computer={3}>
            {this.shareModal(journey)}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

BreadcrumbHeader.propTypes = {
  pathName: PropTypes.string,
  topicName: PropTypes.string,
  eventName: PropTypes.string,
  isMobile: PropTypes.bool,
  journey: PropTypes.object,
  headerText: PropTypes.string
};

const mapStateToProps = store => {
  return {
    journeyTerm: store.json.journeyTerm
  };
};

export default connect(mapStateToProps)(withRouter(BreadcrumbHeader));
