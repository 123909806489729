import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button, Checkbox, Container, Form, Grid, Input } from "semantic-ui-react";
import { submitCredentials } from "../../actions/loginActions";
import { linkToForgotPassword, linkToRegistration } from "../../utils/InternalLinks";

import "./LoginScreen.scss";
import ModalError from "../ModalError/ModalError";

export class LoginScreen extends Component {
  state = {
    email: "",
    password: "",
  };

  handleRef = (component) => (this.ref = component);

  handleEmailChange = (e) => {
    this.setState({ email: e.target.value });
  };

  handlePasswordChange = (e) => {
    this.setState({ password: e.target.value });
  };

  handleSubmit = (e) => {
    this.props.dispatch(submitCredentials({
      email: this.state.email.trim(),
      password: this.state.password,
    }));
  }

  handleForgotPasswordClick = (e) => {
    e.preventDefault();
    return this.props.history.push(linkToForgotPassword());
  };

  handleRegistrationCLick = (e) => {
    e.preventDefault();
    return this.props.history.push(linkToRegistration());
  }

  render() {
    return (
      <Container className="login-screen">
        <ModalError error={this.props.userError} />
        <Form onSubmit={this.handleSubmit.bind(this)}>
          <Form.Field>
            <label id="email-label">Email:</label>
            <Input
              type="text"
              value={this.state.email}
              ref={this.handleRef}
              placeholder="Email"
              aria-labelledby="email-label"
              onChange={this.handleEmailChange.bind(this)}
            />{" "}
            <br />
          </Form.Field>
          <Form.Field>
            <label id="password-label">Password:</label>
            <Input
              type="password"
              value={this.state.password}
              placeholder="Password"
              aria-labelledby="password-label"
              
              onChange={this.handlePasswordChange.bind(this)}
            />
            <br />
          </Form.Field>
        
          <Grid centered>
          <Grid.Row columns={16}>
            <Grid.Column width={6} textAlign="left" className="primary_text">
                <Checkbox
                  type="checkbox"
                  name="remember_me"
                  label={
                    <label>
                      <b id="remember-me-label">Remember Me</b>
                    </label>
                  }
                  aria-labelledby="remember-me-label"
                  value="1"
                  placeholder="Remember Me"
                  className="remember_me"
                />
              </Grid.Column>
              <Grid.Column width={10} textAlign="right">
                <Button
                  className="button-forgot-password"
                  type="button"
                  aria-label="Forgot Password"
                  onClick={this.handleForgotPasswordClick.bind(this)}
                >
                  Forgot Password?
                </Button>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column textAlign="center">
                <Button
                  className="primary"
                  aria-label="Sign In"
                  type="submit"
                >
                  Sign In
                </Button>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={4}>
                <hr />
              </Grid.Column>
              <Grid.Column textAlign="center" className="or-text" width={3}>
                OR
              </Grid.Column>
              <Grid.Column width={4}>
                <hr />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column textAlign="center">
                Don't have an account?
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column textAlign="center">
                <Button
                  className="secondary"
                  aria-label="Register"
                  onClick={this.handleRegistrationCLick.bind(this)}
                >
                  Register
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      </Container>
    )
  }
}

const mapStateToProps = store => {
  return {
    userError: store.login.userError,
  }
}

export default withRouter(connect(mapStateToProps)(LoginScreen))

