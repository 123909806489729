import React, { Component } from "react";
import { Card, Header } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  ImagePlaceholder,
  DescriptionPlaceholder,
  getCustomData
} from "../../utils/HelperFunctions";
import { linkToJourney, linkToExcursion } from "../../utils/InternalLinks";
import PropTypes from "prop-types";
import DynamicImage from "../HelperComponents/DynamicImage";

import CardProgress from "./CardProgress";

export class CardItem extends Component {
  progress() {
    const progress = this.props.progress;

    if (progress) {
      const complete = progress.requirement_completed_count || 0;
      const total = progress.requirement_count || 0;

      return (
        <Card.Content extra className="card-progress-content">
          <CardProgress complete={complete} total={total} />
        </Card.Content>
      );
    } else {
      return null;
    }
  }

  cardDescription() {
    const defaultTextLength = 120;
    const description = getCustomData(
      this.props,
      "description",
      DescriptionPlaceholder()
    );

    if (description.length > defaultTextLength) {
      const shortDescription = description.slice(0, defaultTextLength);
      return shortDescription;
    } else {
      return description;
    }
  }

  cardMeta() {
    const totalPaths = this.props.total_paths || 0;

    if (totalPaths) {
      const totalDisplay = `${totalPaths} Path${totalPaths === 1 ? "" : "s"} `;
      const time = getCustomData(this.props, "time", null);
      const timeDisplay = time ? `(${time})` : null;

      return (
        <Card.Meta>
          {totalDisplay}
          {timeDisplay}
        </Card.Meta>
      );
    }
  }

  render() {
    const cardLink = this.props.journeyId
      ? linkToExcursion(this.props.journeyId, this.props.id)
      : linkToJourney(this.props.id);

    return (
      <Card className="card-item" as={Link} to={cardLink}>
        <DynamicImage
          src={getCustomData(this.props, "thumbnail_url", ImagePlaceholder())}
          alt={getCustomData(this.props, "thumbnail_url_alt", "")}
          wrapped
        />
        <Card.Content>
          <Card.Header className="card-item-header">
            <Header as="h3">{this.props.name}</Header>
          </Card.Header>
          {this.cardMeta()}
          <Card.Description>{this.cardDescription()}</Card.Description>
        </Card.Content>
        {this.props.isUserLoggedIn && this.progress()}
      </Card>
    );
  }
}

CardItem.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  courseCode: PropTypes.string,
  progress: PropTypes.object,
  custom_data: PropTypes.object,
  journeyId: PropTypes.string,
  completed_paths: PropTypes.number,
  total_paths: PropTypes.number
};

const mapStateToProps = store => {
  return {
    isUserLoggedIn: store.login.isUserLoggedIn
  };
};

export default connect(mapStateToProps)(CardItem);
