import React, { Component } from "react";
import { connect } from "react-redux";
import SimpleDropdown from "../HelperComponents/SimpleDropdown/SimpleDropdown";

export class NavigationHelpDropdown extends Component {
  items() {
    const { content } = this.props;

    var itemArray = [
      {
        itemKey: "helpdesk",
        text: "Support",
        ariaLabel: "Go to external Help desk support site will open new tab",

        action: this.props.gotoHelpdesk,
      },
    ];

    if(content && content.length !== undefined) {
      itemArray.push(
        {
          itemKey: "touring",
          text: "Take A Tour",
          ariaLabel: "Open modal to take a tour of site features",
          action: this.props.showTouring,
        }
      )
    }

    return itemArray;
  }

  render() {
    return (
      <SimpleDropdown
        items={this.items()}
        icon={"question circle outline"}
        activeIcon={"question circle"}
        id="navbar-help-dropdown"
        ariaLabel="Expand User Help menu"
      />
    );
  }
}

const mapStateToProps = (store) => ({
  content: store.touring.content
});

export default connect(mapStateToProps)(NavigationHelpDropdown);
