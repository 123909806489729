import React from 'react';
import {Icon} from 'semantic-ui-react';
import PasswordRules from './PasswordRules';
import "./PasswordValidator.scss";

export default class PasswordValidator extends React.Component {
  render() {
    return <div className="passwordValidation">
      {
        PasswordRules.RULES.map((rule, idx) => rule.validator(this.props.password, this.props.confirm) ?
        <div key={`validated-rule-${idx}`} className="validated"><Icon name="check circle" />{rule.rule}</div> :
        <div key={`unvalidated-rule-${idx}`}><Icon />{rule.rule}</div>)
      }
    </div>
  }
}
