import { userClient } from "../utils/axiosClients";

import { reset } from "./globalActions";

export function registerUser(userData) {
  const url = "/sign_up";

  return (dispatch, getState) => {
    return dispatch({
      type: "REGISTER",
      payload: userClient(getState()).post(url, userData),
    });
  };
}

export function previousRegistrationPage() {
  return {
    type: "PREVIOUS_REGISTRATION_PAGE",
  };
}

export function nextRegistrationPage() {
  return {
    type: "NEXT_REGISTRATION_PAGE",
  };
}

export function resetClearLoginForm() {
  return (dispatch) => {
    return dispatch({
      type: "RESET_CLEAR_LOGIN_FORM",
    });
  };
}

export function updateRegistrationDetails(key, value) {
  return {
    type: "UPDATE_REGISTRATION_DETAILS",
    payload: { key: key, value: value },
  };
}

export function loginUser(userData) {
  const { validated, errorMessages } = validationCheck(userData);

  if (validated) {
    const url = "/session";
    return (dispatch, getState) => {
      return dispatch({
        type: "LOGIN",
        payload: userClient(getState()).post(url, userData),
      });
    };
  } else {
    return {
      type: "LOGIN_VALIDATION_FAILED",
      error: errorMessages,
    };
  }
}

export function getUser() {
  const url = "/session";
  return (dispatch, getState) => {
    return dispatch({
      type: "INITIALIZED",
      payload: userClient(getState()).get(url),
    });
  };
}

export function logoutUser() {
  const url = "/session";

  return (dispatch, getState) => {
    return dispatch({
      type: "LOGOUT",
      payload: userClient(getState()).delete(url),
    });
  };
}

export function logoutUserAndReset() {
  return (dispatch) => {
    return dispatch(logoutUser()).then(() => {
      dispatch(reset());
    });
  };
}

export function cleanupLoginErrors() {
  return {
    type: "CLEANUP_LOGIN_ERRORS",
  };
}

export function continueSession() {
  return (dispatch, getState) => {
    return dispatch({
      type: "CONTINUE",
      payload: userClient(getState()).get("/ping"),
    });
  };
}

function validationCheck(userData) {
  const fields = ["password", "email"];
  let validated = true;
  let errorMessages = [];

  // Generate error msg based on field affected
  fields.forEach((element) => {
    if (!userData[element] || userData[element].trim() === "") {
      validated = false;
    }
  });

  if (!validated) {
    errorMessages.push("Please enter a valid email and/or password.");
  }
  return { validated, errorMessages };
}

export function userInBusiness(inBusiness) {
  return {
    type: "USER_IN_BUSINESS",
    payload: inBusiness,
  };
}

export function requestPasswordReset(userData) {
  const url = "/request_password";

  return (dispatch, getState) => {
    return dispatch({
      type: "FORGOT_PASSWORD",
      payload: userClient(getState()).post(url, userData),
    });
  };
}

export function resetPassword(userData) {
  const url = "/reset_password";

  return (dispatch, getState) => {
    return dispatch({
      type: "FORGOT_PASSWORD",
      payload: userClient(getState()).post(url, userData),
    });
  };
}

export function confirmAccount(userData) {
  const url = process.env.REACT_APP_SERVICE_HOST + "/confirmation_code";

  return (dispatch, getState) => {
    return dispatch({
      type: "CONFIRM_ACCOUNT",
      payload: userClient(getState()).post(url, userData),
    });
  };
}

export function clearUserError() {
  return (dispatch) => {
    return dispatch({
      type: "CLEAR_USER_ERROR",
    });
  };
}
