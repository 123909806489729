import React from 'react';
import { Grid, Header } from 'semantic-ui-react';
import ImageContent from "../ContentBlock/ImageContent";
import StyledText from "../LearningEvent/InfographicTemplate/Blocks/StyledText"
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import "./TeaserComponent.scss"
import ParsedHtml from '../HelperComponents/ParsedHtml';

export class TeaserComponent extends React.Component {
  // Accepts title, text, image, alignment and link

  shouldReverse = () => {
    return ((this.props.content) && (this.props.content.align === 'right')) ? 'computer' : null;
  }

  renderImage = () => {
    if (this.props.content && this.props.content.primaryImage) {
      const content = this.props.content || {};
      const size = this.props.isMobile ? (content.mobileImageWidth || "small")
                                            : (content.imageWidth || "small");
      return(
        <Grid.Column textAlign="center">
          <Grid.Row>
            <ImageContent size={size} {...content.primaryImage} />
          </Grid.Row>
        </Grid.Column>
      )
    }
  }

  renderTitle = () => {
    if (this.props.content && this.props.content.title) {
      return <Header as='h1'><StyledText {...this.props.content.title}/></Header>;
    }
  }

  renderBody(body) {
    if(body) {
      return <ParsedHtml html={body} />
    }
  }

  imageMobileWidth = () => {
    const content = this.props.content || {};
    return content.mobileImageWidth || 16;
  }

  imageColumnWidth = () => {
    const content = this.props.content || {};
    return content.align === 'center' ? 5 : content.imageWidth;
  }

  contentColumnWidth = () => {
    const content = this.props.content || {};
    return (content.align === 'center' || content.primaryImage === undefined) ? 16 : this.getColumnWidth(content.contentWidth);
  }

  getColumnWidth(size) {
    switch (size) {
      case "mini":
        return 2;
      case "tiny":
        return 4;
      case "small":
        return 6;
      case "medium":
        return 8;
      case "large":
        return 10;
      case "big":
        return 12;
      case "huge":
        return 14;
      case "massive":
        return 16;
      default:
        return 8;
    }
  }

  render() {
    const content = this.props.content || {};

    return(
      <Grid className='teaser-component' reversed={this.shouldReverse()}>
        <Grid.Row centered>
          { this.renderImage() }
        </Grid.Row>
        <Grid.Row centered>
          <Grid.Column verticalAlign={content.verticalAlign} textAlign="center" computer={this.contentColumnWidth() } mobile={16}>
            {this.renderTitle()}
            {this.renderBody(content.body)}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    )
  }
}

const mapStateToProps = store => {
  return {
    isMobile: store.global.isMobile,
  };
};

export default withRouter(connect(mapStateToProps)(TeaserComponent));