import React, { Component } from "react";
import { connect } from "react-redux";
import NavigationLearningObjectiveItem from "./NavigationLearningObjectiveItem";
import "../../../Navbar.scss";

export class NavigationLearningObjective extends Component {
  elide(text) {
    return text.length < 60 ? text : text.substr(0, 60) + "...";
  }

  getTopicPath(journeyId, objectiveId) {
    return `/${journeyId}/${objectiveId}`;
  }

  render() {
    const { journeyId } = this.props;
    const excursions = this.props.excursions || [];

    const topics = excursions.map((lo, index) => {
      //If this id matches our active topic ID we set the passed in topic ref to the prop
      const topicRef =
        lo.id === this.props.activeTopicId ? this.props.topicRef : null;
      return (
        <NavigationLearningObjectiveItem
          key={index}
          role="option"
          index={index}
          loName={this.elide(lo.name)}
          topicPath={this.getTopicPath(journeyId, lo.id)}
          mleCount={lo.items_count}
          pathId={journeyId}
          objectiveId={lo.id}
          topicRef={topicRef}
        />
      );
    });

    return <div className="ui list navigation-topics-list">{topics}</div>;
  }
}

const mapStateToProps = (store) => {
  return {
    activeTopicId: store.accessibility.mainNav.activeTopicId,
  };
};

export default connect(mapStateToProps)(NavigationLearningObjective);
