import React, { Component } from "react";
import { completeLearningEvent } from "../../../actions/learningEventActions";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import InfographicBlocks from "./InfographicBlocks";

import "./InfographicTemplate.scss";
import ParsedHtml from "../../HelperComponents/ParsedHtml";

export class InfographicTemplate extends Component {
  componentDidMount() {
    this.completeInfographic();
  }

  componentDidUpdate(prevProps) {
    if (this.props.event !== prevProps.event) {
      this.completeInfographic();
    }
  }

  completeInfographic() {
    const { isUserLoggedIn, pathId } = this.props;

    if (isUserLoggedIn) {
      const { id: journeyId, topicId: excursionId } = this.props.match.params;

      this.props.dispatch(
        completeLearningEvent(journeyId, excursionId, pathId)
      );
    }
  }

  render() {
    return (
      <React.Fragment>
        <div id="infographic-template">
          <InfographicBlocks blocks={this.props.blocks} />
        </div>
        <div className="infographic-template-body-field learning-event-body">
          <ParsedHtml html={this.props.body} />
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = store => {
  return { isUserLoggedIn: store.login.isUserLoggedIn };
};

export default withRouter(connect(mapStateToProps)(InfographicTemplate));
