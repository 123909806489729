import React from "react";
import { Link } from "react-router-dom";
import { Button } from "semantic-ui-react";
import "./EventFooterButtons.scss";

export function EventNextButton(props) {
  return (
    <Link tabIndex="-1" className="event-footer-button-link" to={props.url}>
      <Button
        icon="right chevron"
        content={props.text}
        labelPosition="right"
        primary
        className="event-footer-buttons"
      />
    </Link>
  );
}

export function EventPrevButton(props) {
  return (
    <Link className="event-footer-button-link" to={props.url}>
      <Button
        icon="left chevron"
        content="Previous"
        labelPosition="left"
        tabIndex="-1"
        className="event-footer-buttons"
      />
    </Link>
  );
}
