import React from 'react';
import {
  Button,
  Container,
  Divider,
  Form,
  Grid,
  Message,
} from 'semantic-ui-react';
import { toggleLogin } from '../../actions/navbarActions';
import { registerUser } from '../../actions/registrationActions';
import { connect } from "react-redux";
import { Link, withRouter } from 'react-router-dom';
import PasswordRules from "../PasswordValidator/PasswordRules";
import PasswordValidator from '../PasswordValidator/PasswordValidator';
import "./SignUpForm.scss";

export class SignUpForm extends React.Component {
  state = this.getState(this.props);
  handleRef = component => (this.ref = component);

  getState(props) {
    return {
      first_name: this.checkProps("first_name"),
      last_name: this.checkProps("last_name"),
      middle_name: this.checkProps("middle_name"),
      zip_code: this.checkProps("zip_code"),
      email: this.checkProps("email"),
      password: this.checkProps("password"),
      confirm: "",
      errors: {}
    };
  }

  checkProps(propName) {
    try {
      return this.props.userRegistration[propName]
    } catch {
      return '';
    }
  }

  componentDidMount() {
    if (this.ref) {
      this.ref.focus();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.first_name !== this.props.first_name ||
      prevProps.last_name !== this.props.last_name ||
      prevProps.middle_name !== this.props.middle_name ||
      prevProps.zip_code !== this.props.zip_code ||
      prevProps.email !== this.props.email ||
      prevProps.password !== this.props.password ||
      prevProps.errors !== this.props.errors
    ) {
      this.setState(this.getState(prevProps));
    }
    const { userError } = this.props;
    if (userError && userError !== prevProps.userError) {
      this.props.scrollToTop();
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();

    this.props.dispatch(registerUser({
      first_name: this.state.first_name.trim(),
      last_name: this.state.last_name.trim(),
      middle_name: this.state.middle_name.trim(),
      zip_code: this.state.zip_code.trim(),
      email: this.state.email.trim(),
      password: this.state.password.trim(),
    }));
  }

  showLogin = () => {
    this.props.dispatch(toggleLogin(true));
  }

  getChangeHandler(field) {
    return (e) => {
      var state = {};
      state[field] = e.target.value;
      this.setState(state);
    }
  }

  getErrorMessageIfNeeded() {
    if (this.props.userError) {
      const text = this.props.userError.join(" ");
      return (
        <div className="login-error-message">
          <Message negative>
            {text}
          </Message>
        </div>
      )
    };
  };


  getField(field, fieldName, required, refHandler) {
    return <Form.Field required={required}>
              <label>{fieldName}</label>
              <input placeholder={fieldName}
                   name={fieldName}
                   ref={refHandler}
                   value={this.state[field]}
                   aria-label={fieldName}
                   onChange={this.getChangeHandler(field).bind(this)}
              />
            </Form.Field>
  }

  submitEnabled() {
    return (
      this.hasValue(this.state.first_name)
      && this.hasValue(this.state.last_name)
      && this.hasValue(this.state.email)
      && this.hasValue(this.state.zip_code)
      && /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(this.state.zip_code)
      && PasswordRules.valid(this.state.password, this.state.confirm)
    );
  }

  hasValue(value) {
    return value !== undefined && value.length > 0
  }

  render() {
    return (
      <Container className="signup-form">
        <h2>Business Contact Information</h2>
        {this.getErrorMessageIfNeeded()}
        <Form onSubmit={this.handleSubmit.bind(this)}>
          {this.getField('first_name', 'First Name', true, this.handleRef)}
          {this.getField('last_name', 'Last Name', true)}
          {this.getField('middle_name', 'Middle Name')}
          {this.getField('zip_code', 'Zip Code', true)}
          {this.getField('email', 'Email', true)}
          <Form.Field required>
              <label>Password</label>
              <input placeholder="Password" type="password"
                   value={this.state.password}
                   aria-label="Password"
                   onChange={this.getChangeHandler('password').bind(this)}
              /> <br />
          </Form.Field>
          <Form.Field required>
              <label>Confirm Password</label>
              <input placeholder="Confirm Password" type="password"
                   value={this.state.confirm}
                   aria-label="Confirm Password"
                   onChange={this.getChangeHandler('confirm').bind(this)}
              /> <br />
          </Form.Field>
          <PasswordValidator password={this.state.password} confirm={this.state.confirm} />
          <Divider />
          <Grid className="middle aligned">
            <Grid.Row columns={16}>
              <Grid.Column id="account-text" width={12}>
                Already have an account? <Link to="/" onClick={(e) => {e.preventDefault(); this.props.dispatch(toggleLogin(true))}}><b>Login</b></Link>
              </Grid.Column>
              <Grid.Column id="sign-up-next-button" width={4}><Button className="primary" aria-label="Next" type="submit" disabled={!this.submitEnabled()}>Next</Button></Grid.Column>
            </Grid.Row>
          </Grid>
          <Divider />
          <Grid>
            <Grid.Row columns={16}>
              <Grid.Column width={16}>
                <br/>
                <p>
                  The Small Business Act, Public Law (PL) 85-536 authorizes the Small Business Administration (SBA) to
                  collect the information on this electronic format. It is mandatory to register with this learning platform
                  and we will be collecting Name, Zip Code and Email information. By registering you consent to the
                  collection process and storage of your information. In addition, you agree to all SBA.gov policies and
                  disclaimers, including those specifically related to the Ascent platform, 
                  located <a href="https://www.sba.gov/about-sba/open-government/about-sbagov-website" target="_blank" rel="noopener noreferrer">here</a>.
                </p>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Grid className="bg-gray">
            <Grid.Row columns={16}>
              <Grid.Column width={16}>
                <p>
                  PURPOSE: The Small Business Administration (SBA) developed a new e-learning and networking
                  platform for women entrepreneurs interested in accessing resources to support growing an existing
                  business. Anyone can access to the content on the system after registration. It is mandatory that users
                  create an account by completing all the items marked with an asterisk (*) on the Registration Form. The
                  information collection will enable SBA to better determine who is using the platform and the scope of
                  their participation in the a, and to develop a platform that would enable the user to tailor delivery of
                  content to meet their needs. The information will also facilitate user connectivity to relevant resources
                  (e.g., peer-to-peer learning, networking, mentoring)
                </p>
                <p>
                  The information provided will be protected to the extent permitted by law, including the Freedom of
                  Information Act and the Privacy Act. Personally identifiable information that is used to retrieve
                  information or any individual responding to this or other request for information related to SBA training
                  resources is maintained in SBA&#39;s Privacy Act System of Records, SBA - 5 Business and Community
                  Initiatives.
                </p>
                <Grid>
                  <Grid.Row className="mb-control-information">
                    <Grid.Column mobile={16} tablet={8} computer={8}>
                      OMB Control Number: 3245-0399
                    </Grid.Column>
                    <Grid.Column
                      className="omb-expiration"
                      mobile={16}
                      tablet={8}
                      computer={8}
                    >
                      Expiration Date: 05/31/2022
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
                <br />
                <p>
                  NOTE: The Paperwork Reduction Act mandates that all federal government agencies receive approval
                  from OMB. According to the Paperwork Reduction Act, you are not required to respond to the
                  questions asked on this registration form unless it displays a valid OMB Control Number. The estimated
                  time to complete this registration is 8 minutes, including time for reading the instructions. Send
                  comments regarding this estimated time, or any other aspect of this survey to the Chief, Records
                  Management Division, Small Business Administration, 409 Third Street, SW. Washington, DC 20416; or
                  SBA Desk Officer, Office of Management and Budget, New Executive Office Building, Rm. 10202,
                  Washington, DC 20006.
                </p>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      </Container>
    );
  }
};

const mapStateToProps = store => {
  return {
    userRegistration: store.registration.userData,
    userError: store.registration.userError
  }
}

export default withRouter(connect(mapStateToProps)(SignUpForm))
