import React, { Component } from "react";
import { Button, Container, Form, Grid, Input, Message } from "semantic-ui-react";
import { confirmAccount } from "../../actions/registrationActions";
import { toggleConfirmation } from "../../actions/navbarActions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import queryString from "query-string";

export class ConfirmationForm extends Component {
  state = {
    email: '',
    confirmation_code: ''
  };
  handleRef = component => (this.ref = component);

  handleInputChange = (e) => {
    const target = e.target
    const name = target.name
    this.setState({
      [name]: e.target.value
    })
  }

  handleSubmit = (e) => {
    e.preventDefault();

    this.processForm(this.state.email, this.state.confirmation_code);
  }

  setRedirectToHome = () => {
    this.props.dispatch(toggleConfirmation(false));
    return this.props.history.push("/login");
  }

  processForm = (email, confirmation_code) => {
    this.props.dispatch(confirmAccount({
      email: email.trim(),
      code: confirmation_code.trim()
    }));
  }

  componentDidMount() {
    let params = queryString.parse(this.props.location.search);
    let email = (params.email || "").trim();
    let confirmation_code = (params.confirmation_code || "").trim();

    this.setState({
      email: email,
      confirmation_code: confirmation_code || ""
    })

    if( email.length > 0 && confirmation_code.legnth > 0) {
      this.processForm(email, confirmation_code);
    }

    if (this.ref) {
      this.ref.focus();
    }
  }

  getErrorMessageIfNeeded() {
    if (this.props.confirmationErrors["base"]) {
      return(
        <div className="login-error-message">
          <Message negative>
            { this.props.confirmationErrors["base"] }
          </Message>
        </div>
      )
    }
  }

  submitEnabled() {
    return(
      this.state.email.length > 0 && this.state.confirmation_code.length > 0
    )
  }

  getConfirmationForm() {
    return(
      <Container>
        {this.getErrorMessageIfNeeded()}
        <Grid className="middle aligned">
          <Grid.Row>
            <Grid.Column width={16}>Confirm your account with the confirmation code sent to the address.</Grid.Column>
          </Grid.Row>
        </Grid>
        <Form onSubmit={this.handleSubmit.bind(this)}>
          <Form.Field required>
            <label>Email:</label>
            <Input type="text"
                   name="email"
                   value={this.state.email}
                   aria-label="Email"
                   ref={this.handleRef}
                   onChange={this.handleInputChange.bind(this)}
                   placeholder="Email"/> <br />
          </Form.Field>
          <Form.Field required>
            <label>Confirmation Code:</label>
            <Input type="text"
                   name="confirmation_code"
                   value={this.state.confirmation_code}
                   aria-label="Confirmation Code"
                   onChange={this.handleInputChange.bind(this)}
                   placeholder="Confirmation Code"/> <br />
          </Form.Field>

          <Grid centered padded>
          <Grid.Row>
            <Grid.Column textAlign="center">
              <Button className="primary" aria-label="Submit" disabled={!this.submitEnabled()} type="submit">Confirm Account</Button>
            </Grid.Column>
          </Grid.Row>
          </Grid>
        </Form>
      </Container>
    )
  }

  getConfirmationVerified() {
    return(
      <Container>
        <Message positive>
          Congratulations, your account has been confirmed!
        </Message>
        <Grid centered padded>
          <Grid.Row>
            <Grid.Column textAlign="center">
              <Button className="primary" aria-label="Login" onClick={this.setRedirectToHome} type="submit">Login</Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    )
  }

  render() {
    return( this.props.userConfirmed ? this.getConfirmationVerified() : this.getConfirmationForm() )
  }
}

const mapStateToProps = store => {
  return {
    email: store.registration.userData.email,
    userConfirmed: store.registration.userConfirmed,
    confirmationErrors: store.registration.confirmationData.errors,
    isUserLoggedIn: store.login.isUserLoggedIn,
  }
}

export default withRouter(connect(mapStateToProps)(ConfirmationForm))
