import { helpdeskLink } from "./ExternalLinks";

export function StripHtmlTags(someString) {
  if (someString) {
    return someString.replace(/(<([^>]+)>)/gi, "");
  }
  return someString;
}

export function ImagePlaceholder(image) {
  return image ? image : "static_content/Image_Placeholder.png";
}

export function DescriptionPlaceholder(description) {
  return description ? description : ""; //TODO: need to get default text for the description
}

export function getCustomData(customDataParentObject, field, defaultValue) {
  if (
    !customDataParentObject ||
    !customDataParentObject.custom_data ||
    !field ||
    !customDataParentObject.custom_data[field]
  ) {
    return defaultValue;
  }
  return customDataParentObject.custom_data[field];
}

export function getJourneyName(journey) {
  const prefixTitlesEnabled = process.env.REACT_APP_USE_PREFIXED_TITLES
  if (prefixTitlesEnabled === "true" || prefixTitlesEnabled === undefined) {
    return journey.name;
  } else {
    return journey.title;
  }
}

export function getTimeLabel(event) {
  const time = getCustomData(event, "time", null);

  if (time && time.includes && time.split) {
    //the time is stored in custom data as either:
    // "#" for just the minutes or
    // "#:##" for minutes and seconds for videos/podcasts
    if (time.includes(":")) {
      const timeArr = time.split(":");
      const minutes = parseInt(timeArr[0]) || 0;
      const seconds = parseInt(timeArr[1]) || 0;

      const minutesString = minutes && minutes === 1 ? "minute" : "minutes";

      return `(${minutes} ${minutesString} ${seconds} seconds)`;
    } else {
      return `(${time} minutes)`;
    }
  } else {
    return null;
  }
}

const assetHost = process.env.REACT_APP_ASSET_HOST;
const contentHost = process.env.REACT_APP_CONTENT_HOST;
const appCDN = process.env.REACT_APP_CDN;
export function checkExternalLink(element) {
  // Check the link for following conditions
  // Check the link element to see if the href is a .gov domain
  // Check the link to see if it includes the current hostname
  // Check the link to see if it includes the host of the asset bucket
  // Check the link to see if it includes the helpdesk link
  // Check the link to see if it includes the content api host
  // Check the link to see if it includes the CDN

  return (
    element.href &&
    !/(https?:\/\/[a-zA-Z.0-9]+?\.gov($|(\/.*)))|^\//.test(element.href) &&
    /^https?:\/\//i.test(element.href) &&
    !element.href.includes(window.location.hostname) &&
    !(assetHost && element.href.includes(assetHost)) &&
    !element.href.includes(helpdeskLink()) &&
    !(contentHost && element.href.includes(contentHost)) &&
    !(appCDN && element.href.includes(appCDN))
  );
}

export function truncate(string, length) {
  if (string.length <= length) {
    return string;
  }
  return string.substring(0, string.length - 3) + "...";
}

export function numberOfOccurrences(string, subString) {
  string += "";
  subString += "";
  if (subString.length <= 0) return string.length + 1;

  var n = 0,
    pos = 0,
    step = subString.length;

  while (true) {
    pos = string.indexOf(subString, pos);
    if (pos >= 0) {
      ++n;
      pos += step;
    } else break;
  }
  return n;
}

export function formatTime(seconds) {
  const { hh, mm, ss } = timeValues(seconds);
  if (hh) {
    const minutes = padTime(mm);
    return `${hh}:${minutes}:${padTime(ss)}`;
  }
  return `${mm}:${padTime(ss)}`;
}

export function formatReadableTime(seconds) {
  const { hh, mm, ss } = timeValues(seconds);
  if (hh) {
    return `${hh} hours ${mm} minutes ${ss} seconds`;
  }
  return `${mm} minutes ${ss} seconds`;
}

export function timeValues(seconds) {
  const date = new Date(seconds * 1000);
  const hh = date.getUTCHours();
  const mm = date.getUTCMinutes();
  const ss = date.getUTCSeconds();

  return { hh, mm, ss };
}

export function padTime(string) {
  return ("0" + string).slice(-2);
}

export function getViewPortDimension(className, size, activeImageViewPort) {
  if (className) {
    const classArray = className.split(" ");

    let matchedViewPort;
    Object.keys(activeImageViewPort).forEach((key) => {
      if (classArray.includes(key)) {
        matchedViewPort = activeImageViewPort[key];
      }
    });

    if (matchedViewPort) {
      return matchedViewPort;
    }
  }
  const imageSize = size || "default";

  return activeImageViewPort[imageSize];
}

export function imageViewPortSuffix(className, size, activeImageViewPort) {
  const viewPortDimension = getViewPortDimension(
    className,
    size,
    activeImageViewPort
  );
  return `?width=${viewPortDimension}`;
}

const serviceHost = process.env.REACT_APP_SERVICE_HOST;
export function rewriteUrlWithServiceHost(url) {
  if (url) {
    if (url.startsWith("https://s3")) {
      url = serviceHost + "/" + url.split("/").slice(4).join("/");
    } else if (url.startsWith("/")) {
      url = serviceHost + url;
    } else if (!url.startsWith("http")) {
      url = serviceHost + "/" + url;
    }
    return url;
  }
}

export function rewriteUrlWithCDN(url) {
  if (url) {
    if (url.startsWith("https://s3")) {
      url = process.env.REACT_APP_CDN + "/" + url.split("/").slice(4).join("/");
    } else if (url.startsWith("https://")) {
      url = process.env.REACT_APP_CDN + "/" + url.split("/").slice(3).join("/");
    } else if (url.startsWith("/")) {
      url = process.env.REACT_APP_CDN + url;
    } else if (!url.startsWith("http")) {
      url = process.env.REACT_APP_CDN + "/" + url;
    }
    return url;
  }
}

export function parseBGImageStyle(backgroundStyle, activeImageViewPort) {
  if (backgroundStyle && backgroundStyle.backgroundImage) {
    const backgroundArr = backgroundStyle.backgroundImage.split(",");
    let newImageValue = backgroundStyle.backgroundImage;

    backgroundArr.forEach((style) => {
      const reg = /(?:\(['"]?)(.*?)(?:['"]?\))/;
      const url = reg.exec(style) ? reg.exec(style)[1] : null;

      if (url) {
        const newUrl =
          rewriteUrlWithCDN(url) +
          imageViewPortSuffix(null, null, activeImageViewPort);
        newImageValue = newImageValue.replace(url, newUrl);
      }
    });

    return {
      backgroundImage: newImageValue,
    };
  }
}
