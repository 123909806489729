import React, { Component } from "react";
import {
  Button,
  Container,
  Form,
  Grid,
  Header,
  Icon,
  Input,
  Message,
  Modal,
  Divider,
} from "semantic-ui-react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import FocusLock from "react-focus-lock";
import { confirmEmail } from "../../actions/profileActions";
import {
  toggleProfileConfirmation,
  toggleLogin,
} from "../../actions/navbarActions";

export class EmailConfirmationForm extends Component {
  state = {
    confirmation_code: "",
  };
  handleRef = (component) => (this.ref = component);

  handleInputChange = (e) => {
    const target = e.target;
    const name = target.name;
    this.setState({
      [name]: e.target.value,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.processForm();
  };

  processForm = () => {
    if (this.state.confirmation_code) {
      var userData = { code: this.state.confirmation_code.trim() };
      this.props.dispatch(confirmEmail(userData));
    }
  };

  componentDidMount() {
    let params = queryString.parse(this.props.location.search);
    let confirmation_code = (params.confirmation_code || "").trim();

    this.setState({
      confirmation_code: confirmation_code,
    });

    if (this.ref) {
      this.ref.focus();
    }
  }

  getErrorMessageIfNeeded() {
    if (this.props.userError) {
      return (
        <div className="login-error-message">
          <Message negative>{this.props.userError["base"]}</Message>
        </div>
      );
    }
  }

  close = () => {
    this.props.dispatch(toggleProfileConfirmation(false));
    return this.props.history.push("/dashboard");
  };

  submitEnabled = () => {
    return this.state.confirmation_code.length > 0;
  };

  getConfirmationForm() {
    if (this.props.open && !this.props.isUserLoggedIn) {
      this.props.dispatch(toggleLogin(true));
    }

    return (
      <Container>
        {this.getErrorMessageIfNeeded()}
        <Grid className="middle aligned">
          <Grid.Row>
            <Grid.Column width={16}>
              Confirm your new email address with the confirmation code sent to
              that address.
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Form onSubmit={this.handleSubmit.bind(this)}>
          <Form.Field required>
            <label>Confirmation Code:</label>
            <Input
              type="text"
              name="confirmation_code"
              value={this.state.confirmation_code}
              aria-label="Confirmation Code"
              onChange={this.handleInputChange.bind(this)}
              placeholder="Confirmation Code"
            />{" "}
            <br />
          </Form.Field>

          <Grid centered padded>
            <Grid.Row>
              <Grid.Column textAlign="center">
                <Button
                  className="primary"
                  aria-label="Submit"
                  disabled={!this.submitEnabled()}
                  type="submit"
                >
                  Confirm Account
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      </Container>
    );
  }

  getConfirmationVerified() {
    const user = this.props.userData.user || {};
    const email = user.email || "";

    return (
      <Container className="profile-modal-container">
        <div className="profile-modal-messaging">
          <p>
            <span className="profile-modal-emphasis">
              Your email address has successfully been verified.
            </span>
          </p>

          <p>
            You will now be able to receive emails from Ascent to{" "}
            <a href={`mailto: ${email}`}>{email}</a>.
          </p>
        </div>

        <Divider />
        <div className="profile-modal-button-container">
          <Button
            aria-label="Close"
            className=""
            size="large"
            onClick={this.close}
            primary
            content="Close"
          />
        </div>
      </Container>
    );
  }

  render() {
    const { userConfirmed } = this.props;
    const headerText = userConfirmed ? "Email Verified!" : "Confirm New Email";
    return (
      <Modal
        id="edit-profile-modal"
        open={this.props.open}
        closeOnDimmerClick={false}
        aria-modal="true"
        tabIndex="-1"
        size={"tiny"}
      >
        <FocusLock returnFocus={true} className="content">
          <Grid>
            <Grid.Row columns={16}>
              <Grid.Column width={14}>
                <Header id="edit-profile-header">{headerText}</Header>
              </Grid.Column>
              <Grid.Column width={2}>
                <Button
                  aria-label="Close"
                  className="eli-modal-close-button"
                  onClick={this.close}
                  icon
                >
                  <Icon
                    link
                    name="close"
                    size="large"
                    className="edit-profile-close"
                  />
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <hr />
          {userConfirmed
            ? this.getConfirmationVerified()
            : this.getConfirmationForm()}
        </FocusLock>
      </Modal>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    confirmation_code: store.userProfile.confirmationCode,
    userError: store.userProfile.userError,
    userConfirmed: store.userProfile.userConfirmed,
    open: store.navbar.profileModal,
    isUserLoggedIn: store.login.isUserLoggedIn,
    userData: store.login.userData,
  };
};

export default withRouter(connect(mapStateToProps)(EmailConfirmationForm));
