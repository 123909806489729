import React, { Component } from "react";
import { Button, Container, Divider } from "semantic-ui-react";

export class VerificationSent extends Component {
  handleRef = (component) => (this.ref = component);

  handleSubmit = (e) => {
    e.preventDefault();
  };

  render() {
    const { email } = this.props;
    return (
      <Container
        className="profile-modal-container"
        onKeyDown={this.handleKeyPress}
      >
        <div className="profile-modal-messaging">
          <p>
            We have sent an email to <a href={`mailto: ${email}`}>{email}</a>.
          </p>

          <p>
            Please take a look at your inbox for an email verifying your new
            email address. You will not be able to receive any emails from
            Ascent{" "}
            <b>
              <u>until</u>
            </b>{" "}
            your new email address is verified.
          </p>
          <p>
            <span class="note">Note:</span> You must verify your account to use
            the 'Forgot Password' feature.
          </p>
        </div>

        <Divider />
        <div className="profile-modal-button-container">
          <Button
            aria-label="Close"
            className=""
            size="large"
            onClick={this.props.close}
            primary
            content="Close"
          />
        </div>
      </Container>
    );
  }
}

export default VerificationSent;
