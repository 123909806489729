import React, { Component } from "react";
import ContentContainer from "./ContentContainer";
import SplitContent from "./SplitContent";
import ImageContent from "./ImageContent";
import HeroModule from "../Homepage/HeroModule";
import StyledText from "../LearningEvent/InfographicTemplate/Blocks/StyledText";
import MobileDesktopContent from "./MobileDesktopContent";
import HomepageButton from "../Homepage/HomepageBlocks/HomepageButton";
import DividerContent from "./DividerContent";
import CardGrid from "./CardGrid";
import TeaserComponent from "../TeaserComponent/TeaserComponent";

export default class ContentBlock extends Component {
  render() {
    const content = this.props.content.content;

    switch (this.props.content.type) {
      case "container":
        return <ContentContainer content={content}/>
      case "split-content":
        return <SplitContent content={content}/>
      case "image":
        return <ImageContent content={content} className={this.props.className}/>
      case "hero-module":
        return <HeroModule content={content}/>
      case "styled-text":
        return <StyledText content={content}/>
      case "teaser-component":
        return <TeaserComponent content={content}/>
      case "mobile-desktop-content":
        return <MobileDesktopContent content={content}/>
      case "button":
        return <HomepageButton content={content} className={this.props.className}/>
      case "divider":
        return <DividerContent />
      case "card-grid":
        return <CardGrid content={content} />
      default:
        return <div></div>
    }
  }
}
