import React, { Component } from "react";
import { Grid, Progress } from "semantic-ui-react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

export class JourneyProgress extends Component {

  progressPercent = (complete, total) => {
    return (complete / total) * 100;
  }

  eventsCompleted = ( complete, total ) => {
    return (
      <Grid.Row className="journey-completed-row">
        <Grid.Column>
          <span>{`${complete} out of ${total} ${this.props.pathTerm.toLowerCase()}s completed!`}</span>
        </Grid.Column>
      </Grid.Row>
    )
  };

  render() {
    const complete = this.props.complete;
    const total = this.props.total;

    return (
      <Grid className="journey-progress">
        <Grid.Row className="journey-progress-bar-row">
          <Grid.Column>
            <Progress
              color="blue"
              size="tiny"
              percent={this.progressPercent(complete, total)}
              className="journey-progress-bar"
            />
          </Grid.Column>
        </Grid.Row>
        { this.eventsCompleted(complete, total) }
      </Grid>
    )
  }
}

const mapStateToProps = store => {
  return {
    progressPicture: store.json.progressPicture,
    journeyTerm: store.json.journeyTerm,
    pathTerm: store.json.pathTerm
  };
};

export default withRouter(connect(mapStateToProps)(JourneyProgress));
