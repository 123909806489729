import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { linkToDashboard } from "../../utils/InternalLinks";
import SimpleDropdown from "../HelperComponents/SimpleDropdown/SimpleDropdown";

export class NavigationUserDropdown extends Component {
  items() {
    return [
      {
        itemKey: "dashboard",
        text: "Dashboard",
        ariaLabel: "Go To Dashboard",
        action: () => this.props.history.push(linkToDashboard()),
      },
      {
        itemKey: "profile",
        text: "Profile",
        ariaLabel: "Go To Profile",
        action: () => this.props.history.push("/profile"),
      },
      {
        itemKey: "log out",
        text: "Log Out",
        action: () => this.props.logoutUser(),
      },
    ];
  }

  render() {
    const greeting = `Hi, ${this.props.username}`;

    return (
      <SimpleDropdown
        items={this.items()}
        text={greeting}
        id="navbar-user-dropdown"
        className="user-dropdown"
        ariaLabel="Expand User Dashboard Profile Log Out menu"
      />
    );
  }
}

export default withRouter(NavigationUserDropdown);
