import React from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { updateDemographicDetails } from '../../actions/demographicActions';
import CheckboxQuestion from '../CheckboxQuestion/CheckboxQuestion';
import GriddedQuestion from './GriddedQuestion';
import OptionalForm from './OptionalForm';

export class DemographicForm extends React.Component {
  updateState(key, value) {
    this.props.dispatch(updateDemographicDetails(key, value));
  }

  inBusinessValue() {
    if(this.props.inBusiness === "Yes") {
      return true;
    } else {
      return false;
    }
  }


  render() {
    return (
      <OptionalForm nextButton={this.inBusinessValue()} registerButton={!this.inBusinessValue()}>
        <GriddedQuestion number="1">
          <CheckboxQuestion label="Are you in business?" onlyAllowOne={true}
                            answers={["Yes", "No"]}
                            value={this.props.inBusiness}
                            onChange={(value) => this.updateState("in_business", value)}
                            autoFocus={true}/>
        </GriddedQuestion>
        <GriddedQuestion number="2">
          <CheckboxQuestion label="I consider my gender identity" onlyAllowOne={true}
                            answers={["Male", "Female", "Not Listed", "Non-binary / Third Gender", "Prefer to self-describe", "Prefer not to disclose"]}
                            value={this.props.gender}
                            onChange={(value) => this.updateState("gender", value)} />
        </GriddedQuestion>
        <GriddedQuestion number="3">
          <CheckboxQuestion label="I consider myself"
                            answers={["American Indian or Alaska Native", "Asian", "Black or African American", "Native Hawaiian or other Pacific islander", "White"]}
                            value={this.props.race}
                            onChange={(value) => this.updateState("race", value)}/>
        </GriddedQuestion>
        <GriddedQuestion number="4">
          <CheckboxQuestion label="I consider my ethnicity" onlyAllowOne={true}
                            answers={["Hispanic or Latino", "Not Hispanic or Latino"]}
                            value={this.props.ethnicity}
                            onChange={(value) => this.updateState("ethnicity", value)}/>
        </GriddedQuestion>
      </OptionalForm>
    )
  }
}

const mapStateToProps = store => {
  return {
    gender: store.demographic.demographicDetails.gender,
    race: store.demographic.demographicDetails.race,
    ethnicity: store.demographic.demographicDetails.ethnicity,
    inBusiness: store.demographic.demographicDetails.in_business
  }
}

export default withRouter(connect(mapStateToProps)(DemographicForm))
