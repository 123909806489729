import React, { Component } from "react";
import { Button, Container, Divider, Message } from "semantic-ui-react";
import { deleteUserAccount } from "../../actions/profileActions";
import { connect } from "react-redux";

import "../SignUpForm/SignUpForm.scss";

export class DeleteAccount extends Component {
  handleRef = (component) => (this.ref = component);

  handleSubmit = (e) => {
    e.preventDefault();
  };

  getErrorMessageIfNeeded() {
    if (this.props.userError) {
      const text = this.props.userError.join(" ");
      return (
        <div className="edit-profile-error-message">
          <Message negative>{text}</Message>
        </div>
      );
    }
  }

  render() {
    return (
      <Container
        className="profile-modal-container"
        onKeyDown={this.handleKeyPress}
      >
        {this.getErrorMessageIfNeeded()}
        <div className="profile-modal-messaging">
          <p>
            <span className="profile-modal-emphasis">
              We're sad to see you go.
            </span>
          </p>

          <p>
            Deleting your Ascent account will immediately take effect. All
            Journey progress tracking and saved profile information will be
            permanently removed.
          </p>
          <p>Are you sure you want to delete your Ascent account?</p>
        </div>

        <Divider />
        <div className="edit-form-button-group">
          <Button
            aria-label="Delete Account"
            secondary
            size="large"
            content="Delete Account"
            onClick={() => this.props.deleteUserAccount()}
          />
          <Button
            aria-label="Cancel"
            className="form-cancel-button"
            size="large"
            onClick={this.props.close}
            content="Cancel"
          />
        </div>
      </Container>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    userProfile: store.userProfile.userData,
    userError: store.userProfile.userError,
    onProfileSubmit: store.userProfile.onProfileSubmit,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteUserAccount: () => dispatch(deleteUserAccount()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteAccount);
