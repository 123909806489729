import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Container, Header, Button, Grid } from "semantic-ui-react";

import "./MediaTranscript.scss";
import { getCustomData } from "../../utils/HelperFunctions";
import ParsedHtml from "../HelperComponents/ParsedHtml";

export class MediaTranscriptContent extends Component {
  mediaContent() {
    switch (this.props.type) {
      case types.transcript:
        return this.transcript();
      case types.resources:
        return this.resources();
      case types.references:
        return this.sources();
      case types.about:
        return this.about();
      default:
        return null;
    }
  }

  downloadButton() {
    const transcriptUrl = getCustomData(
      this.props.event,
      "transcript_url",
      null
    );

    return transcriptUrl ? (
      <Button
        icon="download"
        floated="right"
        as="a"
        href={process.env.REACT_APP_SERVICE_HOST + transcriptUrl}
        target="_blank"
        aria-label="Link for video transcript sources"
        className="download-button"
      />
    ) : null;
  }

  transcript() {
    const transcriptHtml = getCustomData(
      this.props.event,
      "transcript_html",
      null
    );

    return this.mediaGrid("Transcript", transcriptHtml, this.downloadButton());
  }

  resources() {
    const resourcesHtml = getCustomData(
      this.props.event,
      "resources_html",
      null
    );

    return this.mediaGrid("Resources", resourcesHtml, null);
  }

  sources() {
    const sourcesHtml = getCustomData(
      this.props.event,
      "references_html",
      null
    );

    return this.mediaGrid("References", sourcesHtml, null);
  }

  about() {
    const aboutHtml = getCustomData(this.props.event, "about_html", null);

    return this.mediaGrid("About the Speaker(s)", aboutHtml, null);
  }

  hasMenuItemsToRender() {
    return MediaTranscriptContent.availableTypes(this.props.event).length > 0;
  }

  mediaGrid(headerText, contentHtmlText, downloadButton) {
    return (
      <Grid id="transcript-wrapper">
        <Grid.Row centered className="transcript-header-wrapper">
          <Grid.Column width={16} className="transcript-header-column">
            <Header as="h3" className="transcript-header">
              {headerText}
            </Header>

            {downloadButton}
          </Grid.Column>
        </Grid.Row>

        <Grid.Row centered className="transcript-content-wrapper">
          <Grid.Column width={16} className="transcript-content">
            <div className={`transcript-content-text ${this.props.type}`}>
              <ParsedHtml html={contentHtmlText} />
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }

  render() {
    const hasMenuItemsToRender = this.hasMenuItemsToRender();

    return hasMenuItemsToRender ? (
      <Container className="media-transcript-container">
        {this.props.event ? this.mediaContent() : null}
      </Container>
    ) : (
      ""
    );
  }
}

const types = {
  about: "about",
  transcript: "transcript",
  resources: "resources",
  references: "references",
  comments: "comments"
};

MediaTranscriptContent.propTypes = {
  type: PropTypes.oneOf(Object.keys(types))
};
MediaTranscriptContent.types = types;

MediaTranscriptContent.availableTypes = event => {
  return Object.keys(types).filter(type => {
    return (
      getCustomData(event, type + "_html", null) !== null ||
      (process.env.REACT_APP_COMMENTS === "true" &&
        MediaTranscriptContent.types.comments === type)
    );
  });
};

const mapStateToProps = store => {
  return {
    type: store.learningEvent.mediaTranscriptContent,
    event: store.learningEvent.learningEvent
  };
};

export default connect(mapStateToProps)(MediaTranscriptContent);
