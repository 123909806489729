import React, { Component } from "react";
import StyledText from "./StyledText";
import DescribedImage from "./DescribedImage";

import './Quote.scss';

export default class Quote extends Component {
  imageColumn() {
    if (!this.props.content.image) {
      return null;
    }

    return (
      <div className="column">
        <DescribedImage {...this.props.content.image} />
      </div>
    );
  }

  statementColumn() {
    return (
      <div className="column quote-text">
        { this.quoteText() }
        { this.attribution() }
      </div>
    );
  }

  quoteText() {
    const { content } = this.props;
    const { quoteText } = content;

    if (!quoteText) {
      return null;
    }

    return <StyledText {...quoteText} />
  }

  attribution() {
    const { content } = this.props;
    const { attribution } = content;

    if (!attribution) {
      return null;
    }

    const attributionProps = {
      type: "styled-text",
      content: {
        text: "&mdash;" + attribution.content.text
      },
      style: attribution.style
    }

    const imageSide = attribution.style.innerStyle.textAlign || "right";
    return <div className={"attribution " + imageSide.toLowerCase()}>
             <StyledText {...attributionProps} />
           </div>
  }

  orderedColumns() {
    const { imageSide, mobileImageAlignment } = this.props.content;

    if ((!this.props.useMobileAlignment && imageSide && imageSide.name !== "RIGHT") ||
        (this.props.useMobileAlignment && mobileImageAlignment && mobileImageAlignment.name !== "BOTTOM")) {
      return (
        <React.Fragment>
          {this.imageColumn()}
          {this.statementColumn()}
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          {this.statementColumn()}
          {this.imageColumn()}
        </React.Fragment>
      );
    }
  }

  render() {
    return <div className="row quote-wrapper">{this.orderedColumns()}</div>;
  }
}
