import React from 'react';
import {Container, Dropdown} from 'semantic-ui-react';

import "./DropdownQuestion.scss"

export default class DropdownQuestion extends React.Component {
  handleRef = component => (this.ref = component);

  constructor(props) {
    super(props);
    this.changeHandler = this.changeHandler.bind(this);
  }

  componentDidMount() {
    if (this.ref) {
      this.ref.focus();
    }
  }

  onChange(value) {
    if (this.props.onChange) {
      this.props.onChange(value);
    }
  }

  changeHandler(event, data) {
    this.onChange(data.value);
  }

  getRef() {
    return this.props.autoFocus ? this.handleRef : null;
  }

  render() {
    return (
      <Container className="dropdown-question">
        <p className="dropdown-question-text"><b>{this.props.text}</b></p>
        <Dropdown fluid placeholder="Choose one" selection ref={this.getRef()} aria-label={this.props.text} 
                  onChange={this.changeHandler} value={this.props.value} 
                  options={(this.props.options || []).map((option) => { return { key: option, text: option, value: option }; })} />
      </Container>
    )
  }
}