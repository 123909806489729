import React, { Component } from "react";
import {
  Button,
  Container,
  Form,
  Grid,
  Input,
  Message,
} from "semantic-ui-react";
import { resetPassword } from "../../actions/registrationActions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import "./ResetPasswordForm.scss";
import { toggleForgotPassword } from "../../actions/navbarActions";
import PasswordRules from "../PasswordValidator/PasswordRules";
import PasswordValidator from "../PasswordValidator/PasswordValidator";
import queryString from "query-string";

export class ResetPasswordForm extends Component {
  state = {
    email: "",
    confirmation_code: "",
    password: "",
    password_confirmation: "",
  };
  handleRef = (component) => (this.ref = component);

  handleInputChange = (e) => {
    const target = e.target;
    const name = target.name;
    this.setState({
      [name]: e.target.value,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    this.props.dispatch(
      resetPassword({
        email: this.state.email.trim(),
        confirmation_code: this.state.confirmation_code.trim(),
        password: this.state.password,
        password_confirmation: this.state.password_confirmation,
      })
    );
  };

  componentDidUpdate(prevProps) {
    const { userError } = this.props;
    if (userError && userError !== prevProps.userError) {
      this.props.scrollToTop();
    }
  }

  componentDidMount() {
    if (this.ref) {
      this.ref.focus();
    }

    const params = queryString.parse(this.props.location.search);
    const email = (params.email || "").trim();
    const confirmation_code = (params.confirmation_code || "").trim();

    this.setState({
      email: email,
      confirmation_code: confirmation_code,
    });
  }

  getErrorMessageIfNeeded() {
    if (this.props.userError) {
      const text = this.props.userError.join(" ");
      return (
        <div className="login-error-message">
          <Message negative>{text}</Message>
        </div>
      );
    }
  }

  submitEnabled() {
    return (
      PasswordRules.valid(
        this.state.password,
        this.state.password_confirmation
      ) &&
      this.state.email.length > 0 &&
      this.state.confirmation_code.length > 0
    );
  }

  getResetForm() {
    return (
      <Container>
        {this.getErrorMessageIfNeeded()}
        <Grid className="middle aligned">
          <Grid.Row>
            <Grid.Column width={16}>
              Confirm your email with the confirmation code sent to the address.
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Form autoComplete="off" onSubmit={this.handleSubmit.bind(this)}>
          <Form.Field required>
            <label>Email:</label>
            <Input
              type="text"
              name="email"
              autoComplete="off"
              value={this.state.email}
              aria-label="Email"
              ref={this.handleRef}
              onChange={this.handleInputChange.bind(this)}
              placeholder="Email"
            />
            <br />
          </Form.Field>
          <Form.Field required>
            <label>Confirmation Code:</label>
            <Input
              type="text"
              name="confirmation_code"
              autoComplete="off"
              value={this.state.confirmation_code}
              aria-label="Confirmation Code"
              onChange={this.handleInputChange.bind(this)}
              placeholder="Confirmation Code"
            />
            <br />
          </Form.Field>
          <Form.Field required>
            <label>New Password:</label>
            <Input
              type="password"
              name="password"
              autoComplete="off"
              value={this.state.password}
              aria-label="Password"
              onChange={this.handleInputChange.bind(this)}
              placeholder="New Password"
            />
            <br />
          </Form.Field>
          <Form.Field required>
            <label>Confirm New Password:</label>
            <Input
              type="password"
              name="password_confirmation"
              autoComplete="off"
              value={this.state.password_confirmation}
              aria-label="Password Confirmation"
              onChange={this.handleInputChange.bind(this)}
              placeholder="New Password"
            />
            <br />
          </Form.Field>

          <Grid centered padded>
            <Grid.Row>
              <Grid.Column>
                <PasswordValidator
                  password={this.state.password}
                  confirm={this.state.password_confirmation}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column textAlign="center">
                <Button
                  className="primary"
                  aria-label="Submit"
                  disabled={!this.submitEnabled()}
                  type="submit"
                >
                  Change Password
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      </Container>
    );
  }

  getResetMessage() {
    return (
      <Grid centered>
        <Grid.Row>
          <Grid.Column textAlign="center">
            Your password has changed. Please click &nbsp;
            <Button
              size="medium"
              aria-label="Close"
              onClick={() => {
                this.props.dispatch(toggleForgotPassword(false));
                this.props.history.push("/");
              }}
            >
              here
            </Button>
            &nbsp; to close this window.
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }

  render() {
    let container =
      this.props.onPasswordSubmit && !this.props.userError
        ? this.getResetMessage()
        : this.getResetForm();
    return <Container className="reset-form">{container}</Container>;
  }
}

const mapStateToProps = (store) => {
  return {
    email: store.registration.userData.email,
    password: store.registration.userData.password,
    userError: store.registration.userError,
    onPasswordSubmit: store.registration.onPasswordSubmit,
  };
};

export default withRouter(connect(mapStateToProps)(ResetPasswordForm));
