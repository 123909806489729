import React, { Component } from "react";
import InfographicBlocks from "../InfographicBlocks";

export default class Section extends Component {
  render() {
    const { content } = this.props;

    return <InfographicBlocks blocks={content.blocks} />;
  }
}
