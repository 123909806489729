import React, { Component } from "react";
import { Grid } from "semantic-ui-react";
import { connect } from "react-redux";
import ImageContent from "../ContentBlock/ImageContent";
import HomepageButton from "../Homepage/HomepageBlocks/HomepageButton";
import StyledText from "../LearningEvent/InfographicTemplate/Blocks/StyledText";

import "./Homepage.scss";

export class HeroModule extends Component {
  splashBackgroundImage() {
    const backgrounds = [];

    if (this.props.content &&
        this.props.content.backgroundImage1 &&
        this.props.content.backgroundImage1.content &&
        this.props.content.backgroundImage1.content.imageUrl) {
      backgrounds.push(this.props.content.backgroundImage1.content.imageUrl);
    }
    
    if (this.props.content &&
        this.props.content.backgroundImage2 &&
        this.props.content.backgroundImage2.content &&
        this.props.content.backgroundImage2.content.imageUrl) {
      backgrounds.push(this.props.content.backgroundImage2.content.imageUrl);
    }

    const result = {
      backgroundImage: backgrounds.map(x => `url("` + x + `?width=1920")`).join(", ")
    };
    return result;
  }

  imageRow() {
    if (this.props.content.heroImage) {
      return (
        <Grid.Row centered>
          <ImageContent className="hero-image" {...this.props.content.heroImage}/>
        </Grid.Row>
      )
    } else {
      return null;
    }
  }

  taglineRow() {
    if (this.props.content && this.props.content.tagLine) {
      return (
        <Grid.Row centered className="tag-line-row">
          <Grid.Column className="tag-line" width={8}>
            <StyledText {...this.props.content.tagLine}/>
          </Grid.Column>
        </Grid.Row>
      )
    } else {
      return null;
    }
  }

  buttonRow() {
    if (this.props.content.button) {
      return (
        <Grid.Row centered className="button-row">
          <HomepageButton className="homepage-button" {...this.props.content.button} />
        </Grid.Row>
      )
    } else {
      return null;
    }
  }

  render() {
    if (!this.props.content) {
      return null;
    }
    return (
      <Grid
        id="hero-splash"
        name="hero-splash"
        style={this.splashBackgroundImage()}
      >
        { this.imageRow() }
        { this.taglineRow() }
        { this.buttonRow() }        
      </Grid>
    );
  }
}

export default connect()(HeroModule);
