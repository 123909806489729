import React, { Component } from "react";
import { v4 as uuid } from "uuid";
import DynamicImage from "../../../HelperComponents/DynamicImage";

export default class DescribedImage extends Component {
  render() {
    const { content } = this.props;

    if (content) {
      const id = uuid();
      return (
        <React.Fragment>
          <DynamicImage
            centered
            src={content.imageUrl}
            alt={content.altText}
            aria-describedby={id}/>
          <div id={id} className="screenreader-only">
            {content.describedBy}
          </div>
        </React.Fragment>
      );
    } else {
      return null;
    }
  }
}
